var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container pb-5 mb-1" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-lg-9" }, [
          _c("h2", { staticClass: "mt-5 text-center" }, [
            _vm._v(
              "Welcome to DiamondEye!  If you own a diamond, DiamondEye was created for you!"
            )
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 card border-0" }, [
              _c("div", { staticClass: "row no-gutters" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Use Patented DiamondEye:")
                  ]),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._m(6),
                  _vm._v(" "),
                  _vm._m(7),
                  _vm._v(" "),
                  _vm._m(8),
                  _vm._v(" "),
                  _vm._m(9),
                  _vm._v(" "),
                  _vm._m(10),
                  _vm._v(" "),
                  _vm._m(11),
                  _vm._v(" "),
                  _vm._m(12),
                  _vm._v(" "),
                  _vm._m(13),
                  _vm._v(" "),
                  _vm._m(14),
                  _vm._v(" "),
                  _vm._m(15),
                  _vm._v(" "),
                  _vm._m(16),
                  _vm._v(" "),
                  _vm._m(17),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(18),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6 card border-0" }, [
                      _c("div", { staticClass: "row mt-5 my-4" }, [
                        _c(
                          "div",
                          { staticClass: "text-center col-md-7 offset-md-3" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-lg rounded",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.addProductToCart()
                                  }
                                }
                              },
                              [
                                _vm._v("Order Now"),
                                _c("i", {
                                  staticClass: "ml-2",
                                  attrs: { "data-feather": "arrow-right" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(19)
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(20),
          _vm._v(" "),
          _c("div", { staticClass: "card mt-5" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h3", { staticClass: "card-title text-center" }, [
                _vm._v("Get started in a few simple steps:")
              ]),
              _vm._v(" "),
              _vm._m(21),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "text-center col-md-4 offset-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg rounded",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.addProductToCart()
                        }
                      }
                    },
                    [
                      _vm._v("Start Here"),
                      _c("i", {
                        staticClass: "ml-2",
                        attrs: { "data-feather": "arrow-right" }
                      })
                    ]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(22),
          _vm._v(" "),
          _vm._m(23)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-center my-5" }, [
      _c("div", { staticClass: "col-lg-9" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "embed-responsive embed-responsive-16by9" },
            [
              _c("iframe", {
                attrs: {
                  width: "560",
                  height: "315",
                  src: "https://www.youtube.com/embed/TgO0uEoJBQ8",
                  title: "YouTube video player",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 card border-0" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("What is DiamondEye?")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    The patented DiamondEye is an exciting new technology to identify, protect and verify diamonds using an iPhone or iPad with a specialized viewer and app.\n                                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text font-italic" }, [
              _vm._v(
                "\n                                    You have a beautiful diamond...\n                                "
              )
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "Use DiamondEye to ensure your diamond is always secure in its setting."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "When you take your diamond jewelry in for cleaning or repairs, always know the jeweler returned your beautiful diamond."
                )
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "offset-sm-4 col-sm-4" }, [
        _c("img", {
          staticClass: "mb-grid-gutter",
          attrs: { src: "images/diamondeye-logo.png", alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row pb-2 mt-5" }, [
      _c("div", { staticClass: "offset-sm-3 col-sm-6" }, [
        _c("img", {
          staticClass: "mb-grid-gutter",
          attrs: { src: "images/home-image1.jpg", alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-sm-10" }, [
        _c("p", { staticClass: "ml-5 card-text font-weight-bold" }, [
          _vm._v(
            "\n                                            Protect your diamond from loss\n                                        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          {
            staticClass: "ml-5 ml-sm-0",
            attrs: {
              href: "#collapse1",
              "data-toggle": "collapse",
              role: "button",
              "aria-expanded": "false",
              "aria-controls": "collapseExample"
            }
          },
          [
            _vm._v(
              "\n                                            (learn more)\n                                        "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapse", attrs: { id: "collapse1" } }, [
      _c("ul", { staticClass: "ml-5" }, [
        _c("li", [
          _vm._v(
            "Diamonds can become lost from their settings.  Usually this happens because the diamond has become loose over time.  While wearing your ring, it is easy to bump it against a door, desk or wall.  The setting might look fine but it might need to be fixed."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-sm-10" }, [
        _c("p", { staticClass: "ml-5 card-text font-weight-bold" }, [
          _vm._v(
            "\n                                            Use DiamondEye to make sure your diamond is secure!\n                                        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          {
            staticClass: "ml-5 ml-sm-0",
            attrs: {
              href: "#collapse2",
              "data-toggle": "collapse",
              role: "button",
              "aria-expanded": "false",
              "aria-controls": "collapseExample"
            }
          },
          [
            _vm._v(
              "\n                                            (learn more)\n                                        "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapse", attrs: { id: "collapse2" } }, [
      _c("ul", { staticClass: "ml-5" }, [
        _c("li", [
          _vm._v(
            "Jewelers recommend having your ring inspected monthly, but who has time every month to visit the jeweler? You can now check your setting monthly at home using DiamondEye.  DiamondEye experts will inspect your prongs and notify you if you need to visit your jeweler to fix your prongs."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-sm-10" }, [
        _c("p", { staticClass: "ml-5 card-text font-weight-bold" }, [
          _vm._v(
            "\n                                            Protect your diamond from being swapped\n                                        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          {
            staticClass: "ml-5 ml-sm-0",
            attrs: {
              href: "#collapse3",
              "data-toggle": "collapse",
              role: "button",
              "aria-expanded": "false",
              "aria-controls": "collapseExample"
            }
          },
          [
            _vm._v(
              "\n                                            (learn more)\n                                        "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapse", attrs: { id: "collapse3" } }, [
      _c("ul", { staticClass: "ml-5" }, [
        _c("li", [
          _vm._v(
            "If you give your diamond to a jeweler for cleaning, repair or resizing, will you instantly know they gave the same diamond back to you?"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Was your natural diamond replaced with a low cost lab grown diamond?"
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Was your diamond replaced with a diamond simulant or a less valuable diamond?"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-sm-10" }, [
        _c("p", { staticClass: "ml-5 card-text font-weight-bold" }, [
          _vm._v(
            "\n                                            With DiamondEye, you will know instantly!\n                                        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          {
            staticClass: "ml-5 ml-sm-0",
            attrs: {
              href: "#collapse4",
              "data-toggle": "collapse",
              role: "button",
              "aria-expanded": "false",
              "aria-controls": "collapseExample"
            }
          },
          [
            _vm._v(
              "\n                                            (learn more)\n                                        "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapse", attrs: { id: "collapse4" } }, [
      _c("ul", { staticClass: "ml-5" }, [
        _c("li", [
          _vm._v(
            "Use DiamondEye to remove your worry and doubts about your diamond being swapped.  Verify your diamond before you give it to the jeweler and verify it again when you pick it up.  DiamondEye will instantly confirm that you have the same diamond!"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-sm-10" }, [
        _c("p", { staticClass: "ml-5 card-text font-weight-bold" }, [
          _vm._v(
            "\n                                            Find your lost or stolen diamonds\n                                        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          {
            staticClass: "ml-5 ml-sm-0",
            attrs: {
              href: "#collapse5",
              "data-toggle": "collapse",
              role: "button",
              "aria-expanded": "false",
              "aria-controls": "collapseExample"
            }
          },
          [
            _vm._v(
              "\n                                            (learn more)\n                                        "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapse", attrs: { id: "collapse5" } }, [
      _c("ul", { staticClass: "ml-5" }, [
        _c("li", [
          _vm._v(
            "Over 100 million dollars in jewelry theft happens each year.  If your diamond is lost or stolen, report it to DiamondEye.  We will move your diamond to the SkyVault Lost Diamond Database.  This database is available for police, insurance companies, pawn shops and more to check and verify diamonds.  If your lost diamond is submitted, DiamondEye will identify it as your lost diamond."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-sm-10" }, [
        _c("p", { staticClass: "ml-5 card-text font-weight-bold" }, [
          _vm._v(
            "\n                                            See your diamond and its engraving\n                                        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          {
            staticClass: "ml-5 ml-sm-0",
            attrs: {
              href: "#collapse6",
              "data-toggle": "collapse",
              role: "button",
              "aria-expanded": "false",
              "aria-controls": "collapseExample"
            }
          },
          [
            _vm._v(
              "\n                                            (learn more)\n                                        "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapse", attrs: { id: "collapse6" } }, [
      _c("ul", { staticClass: "ml-5" }, [
        _c("li", [
          _vm._v(
            "The patented DiamondEye Viewer can also be used as a consumer friendly diamond loupe.  Use the Viewer to closely examine any diamond or gemstone.  You can also use it to see your diamond’s girdle engraving."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-sm-10" }, [
        _c("p", { staticClass: "ml-5 card-text font-weight-bold" }, [
          _vm._v(
            "\n                                            Take pictures and share your diamond\n                                        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2" }, [
        _c(
          "a",
          {
            staticClass: "ml-5 ml-sm-0",
            attrs: {
              href: "#collapse7",
              "data-toggle": "collapse",
              role: "button",
              "aria-expanded": "false",
              "aria-controls": "collapseExample"
            }
          },
          [
            _vm._v(
              "\n                                            (learn more)\n                                        "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapse", attrs: { id: "collapse7" } }, [
      _c("ul", { staticClass: "ml-5" }, [
        _c("li", [
          _vm._v(
            "Use the patented DiamondEye Viewer with your phone or tablet to take high magnification images of your diamond.  Share your diamond with your family and friends on social media!"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "offset-sm-2 col-sm-4 mt-4" }, [
      _c("img", {
        staticClass: "mb-grid-gutter",
        attrs: { src: "images/why-image1.jpg", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center col-md-7 offset-md-3 mt-3" }, [
      _c("p", [
        _vm._v("$50 for DiamondEye kit"),
        _c("br"),
        _vm._v("$9.30 for domestic shipping")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 card border-0" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("Created by "),
              _c(
                "a",
                { attrs: { href: "https://gemex.com/", target: "_blank" } },
                [_vm._v("GemEx")]
              ),
              _vm._v(", a leader in diamond evaluation technology.")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    DiamondEye is created by GemEx, the world's leader in evaluating Diamond Light Performance for over 25 years. Over 5 million diamonds have been sold with GemEx Diamond Light Performance reports.\n                                "
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "shopping-cart" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Order your Registration Kit")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "user" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Create your Account")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "check-circle" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Register your Diamond")
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-5 pb-4" }, [
      _c("div", { staticClass: "text-center col-md-4 offset-md-4" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary px-5 mr-2",
            attrs: {
              href:
                "https://gemex.com/diamondeye-user-guide-for-diamondeye-app/",
              target: "_blank"
            }
          },
          [
            _c("i", {
              staticClass: "mr-2",
              attrs: { "data-feather": "book-open" }
            }),
            _vm._v("How to Use DiamondEye")
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card mt-4" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h3", { staticClass: "card-title text-center" }, [
          _vm._v("Download the App")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 text-center" }, [
            _c(
              "a",
              {
                staticClass: "market-btn apple-btn market-btn-light bg-dark",
                attrs: {
                  href:
                    "https://apps.apple.com/id/app/diamondeyepro/id6479329165",
                  role: "button"
                }
              },
              [
                _c("span", { staticClass: "market-button-subtitle" }, [
                  _vm._v("Download on the")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "market-button-title" }, [
                  _vm._v("App Store")
                ])
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }