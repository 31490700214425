var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-title-wrapper",
      attrs: { "aria-label": "Page title" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("h1", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.pageTitle))
        ]),
        _c("span", { staticClass: "d-block mt-2 text-muted" })
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "mt-n1 mr-1" }, [
          _c("i", { attrs: { "data-feather": "home" } })
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "index.html" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Shop")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }