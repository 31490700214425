var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "steps" }, [
    _c(
      "div",
      { staticClass: "steps-body" },
      [
        _c(
          "router-link",
          {
            staticClass: "step",
            class: {
              "step-completed": _vm.isStepCompleted(1),
              "step-active": _vm.isStepActive(1),
              "disabled btn": _vm.isNextStep(1)
            },
            attrs: { to: { name: "cart" } }
          },
          [
            _vm.isStepCompleted(1)
              ? _c("span", { staticClass: "step-indicator" }, [
                  _c("i", { attrs: { "data-feather": "check" } })
                ])
              : _vm._e(),
            _c("span", { staticClass: "step-icon" }, [
              _c("i", { attrs: { "data-feather": "shopping-cart" } })
            ]),
            _vm._v("1. Shopping cart")
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "step",
            class: {
              "step-completed": _vm.isStepCompleted(2),
              "step-active": _vm.isStepActive(2),
              "disabled btn": _vm.isNextStep(2)
            },
            attrs: { to: { name: "checkout-details" } }
          },
          [
            _vm.isStepCompleted(2)
              ? _c("span", { staticClass: "step-indicator" }, [
                  _c("i", { attrs: { "data-feather": "check" } })
                ])
              : _vm._e(),
            _c("span", { staticClass: "step-icon" }, [
              _c("i", { attrs: { "data-feather": "user" } })
            ]),
            _vm._v("2. Your details")
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "step",
            class: {
              "step-completed": _vm.isStepCompleted(3),
              "step-active": _vm.isStepActive(3),
              "disabled btn": _vm.isNextStep(3)
            },
            attrs: { to: { name: "checkout-shipping" } }
          },
          [
            _vm.isStepCompleted(3)
              ? _c("span", { staticClass: "step-indicator" }, [
                  _c("i", { attrs: { "data-feather": "check" } })
                ])
              : _vm._e(),
            _c("span", { staticClass: "step-icon" }, [
              _c("i", { attrs: { "data-feather": "truck" } })
            ]),
            _vm._v("3. Shipping")
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "step",
            class: {
              "step-completed": _vm.isStepCompleted(4),
              "step-active": _vm.isStepActive(4),
              "disabled btn": _vm.isNextStep(4)
            },
            attrs: { to: { name: "checkout-payment" } }
          },
          [
            _vm.isStepCompleted(4)
              ? _c("span", { staticClass: "step-indicator" }, [
                  _c("i", { attrs: { "data-feather": "check" } })
                ])
              : _vm._e(),
            _c("span", { staticClass: "step-icon" }, [
              _c("i", { attrs: { "data-feather": "credit-card" } })
            ]),
            _vm._v("4. Payment")
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "step",
            class: {
              "step-completed": _vm.isStepCompleted(5),
              "step-active": _vm.isStepActive(5),
              "disabled btn": _vm.isNextStep(5)
            },
            attrs: { to: { name: "checkout-review" } }
          },
          [
            _vm.isStepCompleted(5)
              ? _c("span", { staticClass: "step-indicator" }, [
                  _c("i", { attrs: { "data-feather": "check" } })
                ])
              : _vm._e(),
            _c("span", { staticClass: "step-icon" }, [
              _c("i", { attrs: { "data-feather": "check-circle" } })
            ]),
            _vm._v("5. Final review")
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }