<template>
    <div>
        <ul>
            <li v-for="product in products">
                {{ product.description }} - {{ formatAsCurrency(product.price) }}
                <buy-now-button :id="product.id"></buy-now-button>
            </li>
        </ul>
    </div>
</template>

<script>
    import { formatAsCurrency } from '../helper';
    import BuyNowButton from "../components/BuyNowButton";

    export default {
        name: "ProductPage",
        data() {
            return {
            }
        },
        components: {
            BuyNowButton,
        },
        computed: {
            products() {
                return this.$store.state.products;
            }
        },
        methods: {
            formatAsCurrency(value) {
                return formatAsCurrency(value);
            }
        }
    }
</script>

<style scoped>

</style>
