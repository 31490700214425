<template>
    <header class="navbar navbar-expand-lg navbar-light fixed-top bg-light">
        <div class="container-fluid navbar-inner flex-nowrap">
            <!-- navbar brand--><a class="navbar-brand" style="min-width: 75px;" href="/"><img width="75" src="/images/logo.jpg" alt="DiamondEye"/></a>
            <!-- navbar collapse area-->
            <div class="collapse navbar-collapse" id="menu">
                <!-- Site menu-->
                <ul class="navbar-nav">
                    <router-link tag="li" to="/" exact class="nav-item dropdown">
                        <a class="nav-link">DiamondEye<sup>&reg;</sup></a>
                    </router-link>
                    <router-link tag="li" to="/how-it-works" class="nav-item dropdown">
                        <a class="nav-link">How It Works</a>
                    </router-link>
                    <router-link tag="li" to="/diamondeye-viewer" class="nav-item dropdown">
                        <a class="nav-link">DiamondEye<sup>&reg;</sup> Viewer</a>
                    </router-link>
                    <router-link tag="li" to="/plan-comparison" class="nav-item dropdown">
                        <a class="nav-link">Plans</a>
                    </router-link>
                    <li class="nav-item dropdown">
                        <a class="nav-link" target="_blank" href="https://retailer.diamondeye.app/" >For Retailers</a>
                    </li>
                    <router-link tag="li" to="/my-account" class="nav-item dropdown">
                        <a class="nav-link">My Account</a>
                    </router-link>
                </ul>
            </div>
            <!-- navbar buttons-->
            <div class="navbar-btns">
                <div class="navbar-btns-inner">
                    <div class="navbar-toggler navbar-btn collapsed" data-toggle="collapse" data-target="#menu">
                        <i class="mx-auto mb-1" data-feather="menu"></i>Menu
                    </div>
                    <a v-if="!isAuthenticated" class="navbar-btn navbar-collapse-hidden" @click.prevent="login" data-toggle="offcanvas">
                        <i class="mx-auto mb-1" data-feather="log-in"></i>Sign In/Up
                    </a>
                    <a v-else class="navbar-btn navbar-collapse-hidden" @click.prevent="logout" data-toggle="offcanvas">
                        <i class="mx-auto mb-1" data-feather="log-out"></i>Signout
                    </a>
                    <a id="off-canvas-cart" class="navbar-btn" href="#offcanvas-cart" data-toggle="offcanvas">
                        <span class="d-block position-relative">
                            <span class="navbar-btn-badge bg-primary text-light">{{ numOfItemsInCart }}</span>
                            <i class="mx-auto mb-1" data-feather="shopping-cart"></i>{{ totalCartAmount }}
                        </span>
                    </a>
                </div>
            </div>
            <!-- Off-canvas cart-->
            <off-canvas-cart></off-canvas-cart>
        </div>
    </header>
</template>

<script>
    import OffCanvasCart from "../components/OffCanvasCart";
    import { formatAsCurrency } from '../helper';

    export default {
        name: "Header",
        components: {
            OffCanvasCart
        },
        computed: {
            numOfItemsInCart() {
                return this.$store.getters.getNumOfItemsInCart;
            },
            totalCartAmount() {
                return formatAsCurrency(this.$store.getters.getTotalCartAmount);
            }
        },
        methods: {
            login() {
                this.$auth.login({ target: this.$route.path });
            },
            logout() {
                this.$auth.logOut();
                this.$router.push({ path: "/" });
            },
            handleLoginEvent(data) {
                this.isAuthenticated = data.loggedIn;
                this.profile = data.profile;
            }
        },
        data() {
            return {
                isAuthenticated: false,
                profile: {}
            };
        },
        watch:{
            $route (to, from){
                if (to.meta.checkout) {
                    $("#off-canvas-cart").removeAttr("href");
                }
                else {
                    $("#off-canvas-cart").attr("href", "#offcanvas-cart");
                }
            }
        }
    }
</script>

<style scoped>
</style>
