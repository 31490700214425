var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container pb-5 mb-1" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-lg-9" }, [
          _c("h2", { staticClass: "mt-5 mb-5 text-center" }, [
            _vm._v("How It Works!")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h3", { staticClass: "card-title text-center" }, [
                _vm._v("Get started in a few simple steps:")
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "text-center col-md-4 offset-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg rounded",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.addProductToCart()
                        }
                      }
                    },
                    [
                      _vm._v("Start Here"),
                      _c("i", {
                        staticClass: "ml-2",
                        attrs: { "data-feather": "arrow-right" }
                      })
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "shopping-cart" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Order your Registration Kit")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "user" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Create your Account")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "check-circle" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Register your Diamond")
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-5 pb-4" }, [
      _c("div", { staticClass: "text-center col-md-4 offset-md-4" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary px-5 mr-2",
            attrs: {
              href:
                "https://gemex.com/diamondeye-user-guide-for-diamondeye-app",
              target: "_blank"
            }
          },
          [
            _c("i", {
              staticClass: "mr-2",
              attrs: { "data-feather": "book-open" }
            }),
            _vm._v("How to Use DiamondEye")
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "mt-5 card" }, [
          _c(
            "div",
            { staticClass: "embed-responsive embed-responsive-16by9" },
            [
              _c("iframe", {
                attrs: {
                  width: "560",
                  height: "315",
                  src: "https://www.youtube.com/embed/NmEZAeC-Qss?rel=0",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "text-center card-text" }, [
              _vm._v("DiamondEye Kit")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5 card" }, [
          _c(
            "div",
            { staticClass: "embed-responsive embed-responsive-16by9" },
            [
              _c("iframe", {
                attrs: {
                  width: "560",
                  height: "315",
                  src: "https://www.youtube.com/embed/fDWtdP2qWCg?rel=0",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "text-center card-text" }, [
              _vm._v("DiamondEye Account and Registration")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5 card" }, [
          _c(
            "div",
            { staticClass: "embed-responsive embed-responsive-16by9" },
            [
              _c("iframe", {
                attrs: {
                  width: "560",
                  height: "315",
                  src: "https://www.youtube.com/embed/vEewADCd570?rel=0",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "text-center card-text" }, [
              _vm._v("Register Your Diamond")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5 card" }, [
          _c(
            "div",
            { staticClass: "embed-responsive embed-responsive-16by9" },
            [
              _c("iframe", {
                attrs: {
                  width: "560",
                  height: "315",
                  src: "https://www.youtube.com/embed/ZZWBWr1B8Ss?rel=0",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "text-center card-text" }, [
              _vm._v("Verify Your Diamond")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5 card" }, [
          _c(
            "div",
            { staticClass: "embed-responsive embed-responsive-16by9" },
            [
              _c("iframe", {
                attrs: {
                  width: "560",
                  height: "315",
                  src: "https://www.youtube.com/embed/HZEFqmmdVNY?rel=0",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "text-center card-text" }, [
              _vm._v("See Your Diamond")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }