var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media" }, [
    _c("div", { staticClass: "featured-entry-thumb mr-3" }, [
      _c("a", { attrs: { href: "#" } }, [
        _c("img", {
          attrs: {
            src: "images/" + _vm.productImage(),
            width: "64",
            alt: "Product thumb"
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "item-remove-btn",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.removeCartItem()
            }
          }
        },
        [_c("i", { attrs: { "data-feather": "x" } })]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "media-body" }, [
      _c("h6", { staticClass: "featured-entry-title" }, [
        _c("a", { attrs: { href: "#" } }, [
          _vm._v(_vm._s(_vm.productInCart.description))
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "featured-entry-meta" }, [
        _vm._v(_vm._s(_vm.qty) + " "),
        _c("span", { staticClass: "text-muted" }, [_vm._v("x")]),
        _vm._v(" " + _vm._s(_vm.formatAsCurrency(_vm.productInCart.price)))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }