var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bread-crumb", { attrs: { pageTitle: "Checkout" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container pb-5 mb-sm-4 mt-n2 mt-md-n3" },
        [
          _c("checkout-steps", { attrs: { stepNumber: 5 } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row pt-4 mt-2" },
            [
              _c(
                "div",
                { staticClass: "col-xl-9 col-md-8" },
                [
                  _c("h2", { staticClass: "h6 px-4 py-3 bg-secondary mb-4" }, [
                    _vm._v("Review your order")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cartItems, function(cartItem) {
                    return _c("cart-item", {
                      key: cartItem.productId,
                      attrs: {
                        id: cartItem.productId,
                        qty: cartItem.qty,
                        updatable: false
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bg-secondary px-4 pt-4 pb-2 mb-4" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("h4", { staticClass: "h6" }, [
                            _vm._v("Shipping to:")
                          ]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "list-unstyled" }, [
                            _c("li", [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v("Client: ")
                              ]),
                              _vm._v(_vm._s(_vm.fullName))
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v("Address: ")
                              ]),
                              _vm._v(_vm._s(_vm.shipAddress))
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v("Phone: ")
                              ]),
                              _vm._v(_vm._s(_vm.shippingAddress.phone))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("h4", { staticClass: "h6" }, [
                            _vm._v("Payment method:")
                          ]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "list-unstyled" }, [
                            _c("li", [
                              _c("span", { staticClass: "text-muted" }, [
                                _vm._v("Credit Card: ")
                              ]),
                              _vm._v("**** **** **** " + _vm._s(_vm.last4))
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showOrderErrorMessage
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-danger alert-dismissible fade show mb-4",
                          attrs: { role: "alert" }
                        },
                        [
                          _c("span", { staticClass: "font-weight-semibold" }, [
                            _vm._v("Error:")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.orderErrorMessage) +
                              "\n                    "
                          ),
                          _vm._m(0)
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "w-50 pr-3" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-outline-secondary btn-block",
                            attrs: { to: { name: "checkout-payment" } }
                          },
                          [
                            _c("i", {
                              staticClass: "mr-1",
                              attrs: { "data-feather": "chevron-left" }
                            }),
                            _vm._v("Back")
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-50 pl-2" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-block",
                          class: { disabled: _vm.isProcessing },
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.processOrder()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Complete order")
                          ]),
                          _c("span", { staticClass: "d-inline d-sm-none" }, [
                            _vm._v("Complete")
                          ]),
                          _c("i", {
                            staticClass: "ml-1",
                            attrs: { "data-feather": "chevron-right" }
                          })
                        ]
                      )
                    ])
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _c("checkout-order-summary", {
                attrs: { "show-promo-code-form": false }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isProcessing
        ? _c("BlockUI", { attrs: { message: _vm.processMessage } }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                staticStyle: { width: "3rem", height: "3rem" },
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "alert",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }