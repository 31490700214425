var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BlockUI", { attrs: { message: _vm.loadingMessage } }, [
    _c(
      "div",
      {
        staticClass: "spinner-border text-primary",
        staticStyle: { width: "3rem", height: "3rem" },
        attrs: { role: "status" }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }