<template>
    <div>
        <button @click.stop="addProductToCart()">Buy Now</button>
    </div>
</template>

<script>
    export default {
        name: "BuyNowButton",
        props: [ 'id' ],
        methods: {
            addProductToCart() {
                this.$store.dispatch('addProductToCart', this.id);
            }
        }
    }
</script>

<style scoped>

</style>
