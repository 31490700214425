<template>
    <div>
        <div class="container pb-5 mb-1">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <h2 class="mt-5 text-center">Welcome to DiamondEye!  If you own a diamond, DiamondEye was created for you!</h2>

                    <div class="row justify-content-center my-5">
                        <div class="col-lg-9">
                            <div class="card">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <!-- <iframe width="560" height="315"
                                            src="https://www.youtube.com/embed/wCuj6i2yA-I?rel=0" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen>

                                    </iframe> -->
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/TgO0uEoJBQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">What is DiamondEye?</h5>
                                    <p class="card-text">
                                        The patented DiamondEye is an exciting new technology to identify, protect and verify diamonds using an iPhone or iPad with a specialized viewer and app.
                                    </p>
                                    <p class="card-text font-italic">
                                        You have a beautiful diamond...
                                    </p>
                                    <ul>
                                        <li>Use DiamondEye to ensure your diamond is always secure in its setting.</li>
                                        <li>When you take your diamond jewelry in for cleaning or repairs, always know the jeweler returned your beautiful diamond.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="offset-sm-4 col-sm-4">
                            <img class="mb-grid-gutter" src="images/diamondeye-logo.png" alt="">
                        </div>
                    </div>

                    <div class="row pb-2 mt-5">
                        <div class="offset-sm-3 col-sm-6">
                            <img class="mb-grid-gutter" src="images/home-image1.jpg" alt="">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">Use Patented DiamondEye:</h5>
                                    <div class="row mb-3">
                                        <div class="col-sm-10">
                                            <p class="ml-5 card-text font-weight-bold">
                                                Protect your diamond from loss
                                            </p>
                                        </div>
                                        <div class="col-sm-2">
                                            <a class="ml-5 ml-sm-0" href="#collapse1" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                (learn more)
                                            </a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapse1">
                                        <ul class="ml-5">
                                            <li>Diamonds can become lost from their settings.  Usually this happens because the diamond has become loose over time.  While wearing your ring, it is easy to bump it against a door, desk or wall.  The setting might look fine but it might need to be fixed.</li>
                                        </ul>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-10">
                                            <p class="ml-5 card-text font-weight-bold">
                                                Use DiamondEye to make sure your diamond is secure!
                                            </p>
                                        </div>
                                        <div class="col-sm-2">
                                            <a class="ml-5 ml-sm-0" href="#collapse2" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                (learn more)
                                            </a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapse2">
                                        <ul class="ml-5">
                                            <li>Jewelers recommend having your ring inspected monthly, but who has time every month to visit the jeweler? You can now check your setting monthly at home using DiamondEye.  DiamondEye experts will inspect your prongs and notify you if you need to visit your jeweler to fix your prongs.</li>
                                        </ul>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-10">
                                            <p class="ml-5 card-text font-weight-bold">
                                                Protect your diamond from being swapped
                                            </p>
                                        </div>
                                        <div class="col-sm-2">
                                            <a class="ml-5 ml-sm-0" href="#collapse3" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                (learn more)
                                            </a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapse3">
                                        <ul class="ml-5">
                                            <li>If you give your diamond to a jeweler for cleaning, repair or resizing, will you instantly know they gave the same diamond back to you?</li>
                                            <li>Was your natural diamond replaced with a low cost lab grown diamond?</li>
                                            <li>Was your diamond replaced with a diamond simulant or a less valuable diamond?</li>
                                        </ul>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-10">
                                            <p class="ml-5 card-text font-weight-bold">
                                                With DiamondEye, you will know instantly!
                                            </p>
                                        </div>
                                        <div class="col-sm-2">
                                            <a class="ml-5 ml-sm-0" href="#collapse4" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                (learn more)
                                            </a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapse4">
                                        <ul class="ml-5">
                                            <li>Use DiamondEye to remove your worry and doubts about your diamond being swapped.  Verify your diamond before you give it to the jeweler and verify it again when you pick it up.  DiamondEye will instantly confirm that you have the same diamond!</li>
                                        </ul>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-sm-10">
                                            <p class="ml-5 card-text font-weight-bold">
                                                Find your lost or stolen diamonds
                                            </p>
                                        </div>
                                        <div class="col-sm-2">
                                            <a class="ml-5 ml-sm-0" href="#collapse5" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                (learn more)
                                            </a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapse5">
                                        <ul class="ml-5">
                                            <li>Over 100 million dollars in jewelry theft happens each year.  If your diamond is lost or stolen, report it to DiamondEye.  We will move your diamond to the SkyVault Lost Diamond Database.  This database is available for police, insurance companies, pawn shops and more to check and verify diamonds.  If your lost diamond is submitted, DiamondEye will identify it as your lost diamond.</li>
                                        </ul>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-10">
                                            <p class="ml-5 card-text font-weight-bold">
                                                See your diamond and its engraving
                                            </p>
                                        </div>
                                        <div class="col-sm-2">
                                            <a class="ml-5 ml-sm-0" href="#collapse6" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                (learn more)
                                            </a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapse6">
                                        <ul class="ml-5">
                                            <li>The patented DiamondEye Viewer can also be used as a consumer friendly diamond loupe.  Use the Viewer to closely examine any diamond or gemstone.  You can also use it to see your diamond’s girdle engraving.</li>
                                        </ul>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-10">
                                            <p class="ml-5 card-text font-weight-bold">
                                                Take pictures and share your diamond
                                            </p>
                                        </div>
                                        <div class="col-sm-2">
                                            <a class="ml-5 ml-sm-0" href="#collapse7" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                (learn more)
                                            </a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapse7">
                                        <ul class="ml-5">
                                            <li>Use the patented DiamondEye Viewer with your phone or tablet to take high magnification images of your diamond.  Share your diamond with your family and friends on social media!</li>
                                        </ul>
                                    </div>
                                    <div class="row">
                                        <div class="offset-sm-2 col-sm-4 mt-4"><img class="mb-grid-gutter" src="images/why-image1.jpg" alt=""></div>
                                        <div class="col-sm-6 card border-0">
                                            <div class="row mt-5 my-4">
                                                <div class="text-center col-md-7 offset-md-3">
                                                    <button type="button" class="btn btn-primary btn-lg rounded" @click.stop="addProductToCart()">Order Now<i class="ml-2" data-feather="arrow-right"></i></button>
                                                </div>
                                                <div class="text-center col-md-7 offset-md-3 mt-3">
                                                    <p>$50 for DiamondEye kit<br/>$9.30 for domestic shipping</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">Created by <a href="https://gemex.com/" target="_blank">GemEx</a>, a leader in diamond evaluation technology.</h5>
                                    <p class="card-text">
                                        DiamondEye is created by GemEx, the world's leader in evaluating Diamond Light Performance for over 25 years. Over 5 million diamonds have been sold with GemEx Diamond Light Performance reports.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-5">
                        <div class="card-body">
                            <h3 class="card-title text-center">Get started in a few simple steps:</h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="shopping-cart"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Order your Registration Kit</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="user"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Create your Account</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="check-circle"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Register your Diamond</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="text-center col-md-4 offset-md-4">
                                    <button type="button" class="btn btn-primary btn-lg rounded" @click.stop="addProductToCart()">Start Here<i class="ml-2" data-feather="arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5 pb-4">
                        <div class="text-center col-md-4 offset-md-4">
                            <a href="https://gemex.com/diamondeye-user-guide-for-diamondeye-app/" target="_blank" class="btn btn-primary px-5 mr-2"><i class="mr-2" data-feather="book-open"></i>How to Use DiamondEye</a>
                        </div>
                    </div>

                    <div class="card mt-4">
                        <div class="card-body">
                            <h3 class="card-title text-center">Download the App</h3>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <a href="https://apps.apple.com/id/app/diamondeyepro/id6479329165" class="market-btn apple-btn market-btn-light bg-dark" role="button">
                                        <span class="market-button-subtitle">Download on the</span>
                                        <span class="market-button-title">App Store</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import feather from 'feather-icons';
    import StartHereButton from "../components/StartHereButton";
    import router from "../router";

    export default {
        name: "HomePage",
        components: {StartHereButton},
        methods: {
            addProductToCart() {
                this.$store.dispatch('addProductToCart', this.DIAMOND_VIEWER_KIT_PRODUCT_ID);
                this.$modal.show('dialog', {
                    text: 'A Viewer Kit has been added to your cart.',
                    buttons: [{
                        title: 'Close',
                        class: 'btn btn-info'
                    }
                    ]
                });
                router.push({ path: 'plan-comparison' });
            },
            addSpecialToCart() {
                this.$store.dispatch('addProductToCart', this.DIAMOND_VIEWER_KIT_PRODUCT_ID);
                this.$store.dispatch('addProductToCart', this.GOLD_MEMBERSHIP);
                router.push({ path: 'cart' });
            }
        },
        created() {
            this.DIAMOND_VIEWER_KIT_PRODUCT_ID = 1;
            this.GOLD_MEMBERSHIP = 2;
        },
        mounted() {
            feather.replace();
        }
    }
</script>

<style scoped>
    a[aria-expanded=true] .plus-circle {
        display: none;
    }
    a[aria-expanded=false] .minus-circle {
        display: none;
    }

    .card-header-text {
        margin-bottom: 2px;
        display: inline;
    }
</style>
