import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from "./auth/authService";
import store from './store';

import HomePage from "./pages/HomePage.vue";
import Callback from "./components/Callback.vue";
import ProductPage from "./pages/ProductPage.vue";
import AboutPage from "./pages/AboutPage.vue";
import ShoppingCartPage from "./pages/ShoppingCartPage";
import CheckoutDetailsPage from "./pages/CheckoutDetailsPage";
import CheckoutShippingPage from "./pages/CheckoutShippingPage";
import CheckoutPaymentPage from "./pages/CheckoutPaymentPage.vue";
import CheckoutReviewPage from "./pages/CheckoutReviewPage.vue";
import CheckoutCompletePage from "./pages/CheckoutCompletePage";
import WhatIsDiamondEyePage from "./pages/WhatIsDiamondEyePage";
import PlanComparison from "./pages/PlanComparison";
import MyAccount from "./pages/MyAccount";
import MyDiamondPage from "./pages/MyDiamondPage";
import DiamondEyeViewerPage from "./pages/DiamondEyeViewerPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import HowItWorks from "./pages/HowItWorks";

Vue.use(VueRouter);

let router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', name: 'home', meta: { public: true }, component: HomePage },
        { path: '/how-it-works', name: 'how-it-works', meta: { public: true }, component: HowItWorks },
        { path: '/what-is-diamondeye', name: 'what-is-diamondeye', meta: { public: true }, component: WhatIsDiamondEyePage },
        { path: '/diamondeye-viewer', name: 'diamoneye-viewer', meta: { public: true }, component: DiamondEyeViewerPage },
        { path: '/plan-comparison', name: 'plan-comparison', meta: { public: true }, component: PlanComparison },
        { path: '/my-account', name: 'my-account', component: MyAccount },
        { path: '/diamonds/:diamondId', name: 'my-diamond', meta: { public: true }, component: MyDiamondPage },
        { path: '/cart', name: 'cart', meta: { checkout: true }, component: ShoppingCartPage },
        { path: '/checkout-details', name: 'checkout-details', meta: { checkout: true }, component: CheckoutDetailsPage },
        { path: '/checkout-shipping', name: 'checkout-shipping', meta: { checkout: true }, component: CheckoutShippingPage },
        { path: '/checkout-payment', name: 'checkout-payment',  meta: { checkout: true }, component: CheckoutPaymentPage },
        { path: '/checkout-review', name: 'checkout-review',  meta: { checkout: true }, component: CheckoutReviewPage },
        { path: '/checkout-complete/:orderNumber/:requiresShipping', name: 'checkout-complete',  meta: { checkout: true }, component: CheckoutCompletePage, props: true },
        //{ path: '/about', name: 'about', component: AboutPage },
        { path: "/callback", name: "callback", component: Callback },
        { path: '/products', name: 'products', meta: { public: true }, component: ProductPage },
        { path: '/terms-of-use', name: 'term-of-use', meta: { public: true }, component: TermsOfUsePage },
        { path: '/privacy', name: 'privacy', meta: { public: true }, component: PrivacyPolicyPage }
    ],
    linkActiveClass: "active",
    linkExactActiveClass: "active"
});

router.beforeEach((to, from, next) => {
    if (to.path === "/" || to.path === "/callback" || to.path === '/cart' || to.meta.public || auth.isAuthenticated()) {
        return next();
    }
    auth.login({ target: to.fullPath });
});

router.beforeEach((to, from, next) => {
    if (to.meta.checkout) {
        // if (store.getters['getNumOfItemsInCart'] === 0) {
        //     return next('/');
        // }
        if ((! store.getters['hasActivePlan']) && (store.getters['getPlanInCart'] == null)) {
            if (from.path !== '/plan-comparison') {
                return next('/plan-comparison?selectPlan=true');
            }
            else {
                return router.go('/plan-comparison?selectPlan=true');
            }
        }
        if (to.path === "/checkout-shipping") {
            if ((! store.getters['getRequiresShipping']) || ((from.path !== '/checkout-details') && (from.path !== '/checkout-payment'))) {
                return next('/checkout-details');
            }
        }
        if (to.path === "/checkout-payment") {
            if (store.getters['getRequiresShipping']) {
                if ((from.path !== '/checkout-shipping') && (from.path !== '/checkout-review')) {
                    return next('/checkout-details');
                }
            }
            else {
                if ((from.path !== '/checkout-details') && (from.path !== '/checkout-review')) {
                    return next('/checkout-details');
                }
            }
        }
        if ((to.path === "/checkout-review") && (from.path !== '/checkout-payment')) {
            return next('/checkout-details');
        }
    }

    return next();
});

export default router;
