var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "container pb-5 mb-2" }, [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        !_vm.isAuthenticated
          ? _c("div", [
              _c("h4", [_vm._v("Select a plan and check out in the cart")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("If you already have an account with us, please "),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.login($event)
                      }
                    }
                  },
                  [_vm._v("sign in")]
                ),
                _vm._v(" first.")
              ])
            ])
          : !_vm.hasActivePlan
          ? _c("div", [
              _c("h4", [_vm._v("Select a plan and check out in the cart")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "You are not subscribed to an active plan. Please select one."
                )
              ])
            ])
          : _c("div", [
              _c("h6", [
                _vm._v(
                  "You are currently subscribed to the " +
                    _vm._s(_vm.currentPlan.description) +
                    "."
                )
              ])
            ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xl-2 col-md-3 pt-3 pt-md-0" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary btn-block",
                class: { disabled: !_vm.enableCheckout },
                attrs: { to: { name: "checkout-details" } }
              },
              [_vm._v("Checkout")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "comparison-table" }, [
        _c("table", { staticClass: "mt-3 table table-bordered" }, [
          _c("thead", { staticClass: "bg-secondary" }, [
            _c("tr", [
              _c(
                "td",
                [
                  _c("plan-comparison-header", {
                    attrs: { "product-key": "PLAN_SILVER" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("plan-comparison-header", {
                    attrs: { "product-key": "PLAN_GOLD" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("plan-comparison-header", {
                    attrs: { "product-key": "PLAN_DIAMOND" }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(1)
        ]),
        _vm._v(" "),
        _vm._m(2)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "page-title-wrapper",
        attrs: { "aria-label": "Page title" }
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("h1", { staticClass: "page-title" }, [_vm._v("Plan comparison")]),
          _c("span", { staticClass: "d-block mt-2 text-muted" }),
          _vm._v(" "),
          _c("hr", { staticClass: "mt-4" })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tbody", { attrs: { id: "summary", "data-filter": "target" } }, [
      _c("tr", { staticClass: "bg-secondary" }, [
        _c("td", [
          _c("span", { staticClass: "text-dark font-weight-semibold" }, [
            _vm._v("Free")
          ])
        ]),
        _vm._v(" "),
        _c("td", [
          _c("span", { staticClass: "text-dark font-weight-semibold" }, [
            _vm._v("$1 per month (billed annually)")
          ])
        ]),
        _vm._v(" "),
        _c("td", [
          _c("span", { staticClass: "text-dark font-weight-semibold" }, [
            _vm._v("$2 per month (billed annually)")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [
          _vm._v(
            "Registration of one solitaire diamond included with DiamondEye kit"
          )
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            "Registration of one solitaire diamond included with DiamondEye kit"
          )
        ]),
        _vm._v(" "),
        _c("td", [
          _vm._v(
            "Registration of one solitaire diamond included with DiamondEye kit"
          )
        ])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Verification: $10 each")]),
        _vm._v(" "),
        _c("td", [_vm._v("Verification: 4 included per year")]),
        _vm._v(" "),
        _c("td", [_vm._v("Verification: 10 included per year")])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Prong Check $4 each")]),
        _vm._v(" "),
        _c("td", [_vm._v("Prong Check: 12 included per year")]),
        _vm._v(" "),
        _c("td", [_vm._v("Prong Check: 24 included per year")])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Additional Diamond Registration: $40 per diamond")]),
        _vm._v(" "),
        _c("td", [_vm._v("Additional Diamond Registration: $25 per diamond")]),
        _vm._v(" "),
        _c("td", [_vm._v("Additional Diamond Registration: $20 per diamond")])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Register with the Missing/Lost Database: $50")]),
        _vm._v(" "),
        _c("td", [_vm._v("Register with the Missing/Lost Database: $25")]),
        _vm._v(" "),
        _c("td", [_vm._v("Register with the Missing/Lost Database: Free")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card mt-5" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h3", { staticClass: "card-title text-center" }, [
          _vm._v("Download the App")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 text-center" }, [
            _c(
              "a",
              {
                staticClass: "market-btn apple-btn market-btn-light bg-dark",
                attrs: {
                  href:
                    "https://apps.apple.com/id/app/diamondeyepro/id6479329165",
                  role: "button"
                }
              },
              [
                _c("span", { staticClass: "market-button-subtitle" }, [
                  _vm._v("Download on the")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "market-button-title" }, [
                  _vm._v("App Store")
                ])
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }