<template>
    <div>
        <div class="container pb-5 mb-1">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row mt-4">
                        <div class="col-sm-4 mt-4"><img class="mb-grid-gutter" src="images/why-image1.jpg" alt=""></div>
                        <div class="col-sm-8 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">Verify and Protect your diamonds</h5>
                                    <p class="card-text">
                                        Register your diamond in the SkyVault database using the DiamondEye App, the Viewer and your phone or tablet.
                                    </p>
                                    <p class="card-text">
                                        Option 1: Use Prong Check on the App.  DiamondEye will compare the prongs and the diamond security to your previous registration.  You will be notified within 1 day on the status of your prongs.
                                    </p>
                                    <p class="card-text">
                                        Option 2: Verify your diamond using the same process.  The DiamondEye algorithm will compare and confirm the verification diamond is the same as the original.  You will receive verification within minutes.
                                    </p>
                                    <p class="card-text">
                                        DiamondEye uses automated system review and expert review to confirm that your prongs are secure and your diamond is the same.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-8 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <h5 class="card-title">The DiamondEye Process</h5>
                                    <p class="card-text">
                                        Register your diamond in the SkyVault database using the DiamondEye App, the Viewer and your phone or tablet.
                                    </p>
                                    <p class="card-text">
                                        Option 1: Use Prong Check on the App.  DiamondEye will compare the prongs and the diamond security to your previous registration.  You will be notified within 1 day on the status of your prongs.
                                    </p>
                                    <p class="card-text">
                                        Option 2: Verify your diamond using the same process.  The DiamondEye algorithm will compare and confirm the verification diamond is the same as the original.  You will receive verification within minutes.
                                    </p>
                                    <p class="card-text">
                                        DiamondEye uses automated system review and expert review to confirm that your prongs are secure and your diamond is the same.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-5">
                        <div class="card-body">
                            <h3 class="card-title text-center">Get started in a few simple steps:</h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="shopping-cart"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Order your Registration Kit</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="user"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Create your Account</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="check-circle"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Register your Diamond</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="text-center col-md-4 offset-md-4">
                                    <StartHereButton></StartHereButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-5">
                        <div class="card-body">
                            <h3 class="card-title text-center">Download the App</h3>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <a href="https://apps.apple.com/id/app/diamondeyepro/id6479329165" class="market-btn apple-btn market-btn-light bg-dark" role="button">
                                        <span class="market-button-subtitle">Download on the</span>
                                        <span class="market-button-title">App Store</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import feather from 'feather-icons';
    import StartHereButton from "../components/StartHereButton";

    export default {
        name: "WhatIsDiamondEyePage",
        components: {StartHereButton},
        mounted() {
            feather.replace();
        }
    }
</script>

<style scoped>

</style>
