/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vuelidate from 'vuelidate';
import App from './layouts/App.vue';
import router from './router';
import store from './store';
import auth from './auth/auth';
import BlockUI from 'vue-blockui';
import VModal from 'vue-js-modal';
import VueGtag from "vue-gtag";
import vueCountryRegionSelect from 'vue-country-region-select'

Vue.use(auth);
Vue.use(Vuelidate);
Vue.use(BlockUI);
Vue.use(VModal, { dialog: true});
Vue.use(VueGtag, {
    config: { id: "UA-6538727-6" },
    includes: [{
        id: 'AW-1071484966'
    }]
}, router);
Vue.use(vueCountryRegionSelect);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

//const files = require.context('./', true, /\.vue$/i);
//files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
});

