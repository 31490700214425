<template>
    <div class="steps">
        <div class="steps-body">
            <router-link :to="{ name: 'cart'}" class="step" v-bind:class="{ 'step-completed': isStepCompleted(1), 'step-active': isStepActive(1), 'disabled btn': isNextStep(1) }"><span v-if="isStepCompleted(1)" class="step-indicator"><i data-feather="check"></i></span><span class="step-icon"><i data-feather="shopping-cart"></i></span>1. Shopping cart</router-link>
            <router-link :to="{ name: 'checkout-details'}" class="step" v-bind:class="{ 'step-completed': isStepCompleted(2), 'step-active': isStepActive(2), 'disabled btn': isNextStep(2) }"><span v-if="isStepCompleted(2)" class="step-indicator"><i data-feather="check"></i></span><span class="step-icon"><i data-feather="user"></i></span>2. Your details</router-link>
            <router-link :to="{ name: 'checkout-shipping'}" class="step" v-bind:class="{ 'step-completed': isStepCompleted(3), 'step-active': isStepActive(3), 'disabled btn': isNextStep(3) }"><span v-if="isStepCompleted(3)" class="step-indicator"><i data-feather="check"></i></span><span class="step-icon"><i data-feather="truck"></i></span>3. Shipping</router-link>
            <router-link :to="{ name: 'checkout-payment'}" class="step" v-bind:class="{ 'step-completed': isStepCompleted(4), 'step-active': isStepActive(4), 'disabled btn': isNextStep(4) }"><span v-if="isStepCompleted(4)" class="step-indicator"><i data-feather="check"></i></span><span class="step-icon"><i data-feather="credit-card"></i></span>4. Payment</router-link>
            <router-link :to="{ name: 'checkout-review'}" class="step" v-bind:class="{ 'step-completed': isStepCompleted(5), 'step-active': isStepActive(5), 'disabled btn': isNextStep(5) }"><span v-if="isStepCompleted(5)" class="step-indicator"><i data-feather="check"></i></span><span class="step-icon"><i data-feather="check-circle"></i></span>5. Final review</router-link>
        </div>
    </div>
</template>

<script>
    // a class="step step-completed"><span v-if="isStepCompleted" class="step-indicator"><i data-feather="check"></i></span>
    // a class="step step-active"

    export default {
        name: "CheckoutSteps",
        props: [ 'stepNumber' ],
        methods: {
            isStepCompleted: function(step) {
                return step < this.stepNumber;
            },
            isStepActive: function(step) {
                return step === this.stepNumber;
            },
            isNextStep: function(step) {
                return step > this.stepNumber;
            }
        }
    }
</script>

<style scoped>

</style>
