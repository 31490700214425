var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "offcanvas offcanvas-reverse",
      attrs: { id: "offcanvas-cart" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "offcanvas-body" }, [
        _c(
          "div",
          { staticClass: "offcanvas-body-inner" },
          [
            _vm.numOfItemsInCart === 0
              ? _c(
                  "h2",
                  { staticClass: "h6 mt-2 px-4 py-3 bg-secondary text-center" },
                  [_vm._v("Your cart is empty")]
                )
              : [
                  _c("div", { staticClass: "text-right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "text-danger btn-sm pr-0",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.clearCart()
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "mr-1",
                          staticStyle: { width: ".85rem", height: ".85rem" },
                          attrs: { "data-feather": "x" }
                        }),
                        _vm._v("Clear cart")
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "widget widget-featured-entries pt-3" },
                    [
                      _vm._l(_vm.cartItems, function(cartItem) {
                        return _c("cart-item-small", {
                          key: cartItem.productId,
                          attrs: { id: cartItem.productId, qty: cartItem.qty }
                        })
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center py-3"
                        },
                        [
                          _c("div", { staticClass: "font-size-sm" }, [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v("Subtotal:")
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-weight-semibold text-dark" },
                              [_vm._v(_vm._s(_vm.totalCartAmount))]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-outline-secondary btn-sm",
                              attrs: { to: { name: "cart" } }
                            },
                            [
                              _vm._v("Expand cart"),
                              _c("i", {
                                staticClass: "mr-2",
                                attrs: { "data-feather": "chevron-right" }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary btn-sm btn-block",
                          attrs: { to: { name: "checkout-details" } }
                        },
                        [
                          _c("i", {
                            staticClass: "mr-1",
                            attrs: { "data-feather": "credit-card" }
                          }),
                          _vm._v("Checkout")
                        ]
                      )
                    ],
                    2
                  )
                ]
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "offcanvas-header d-flex justify-content-between align-items-center"
      },
      [
        _c("h3", { staticClass: "offcanvas-title" }, [_vm._v("Your cart")]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              id: "close-offcanvas-cart",
              type: "button",
              "data-dismiss": "offcanvas",
              "aria-label": "Close"
            }
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }