import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

import axios from 'axios';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

export default new Vuex.Store({
    strict: true,
    state: {
        products: [],
        currentPlan: {
            id: null,
            name: null,
            description: null,
            productKey: null,
            price: null,
            subscriptionEndDateTime: null,
            numOfRegistrationAllowed: null,
            numOfVerificationsAllowed: null,
            numOfProngChecksAllowed: null,
            additionalRegistrationPrice: null,
            additionalVerificationPrice: null,
            additionalProngCheckPrice: null
        },
        cart: [],
        shippingMethod: "0",
        shippingAmount: 0,
        isFirstOrder: false,
        leadSource: 'Google Search',
        shippingAddress: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            phone: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalcode: '',
            country: ''
        },
        billingAddress: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            phone: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalcode: '',
            country: ''
        },
        stripeSource: null,
        discountCodeApplied: null,
        discountAmount: 0,
        last4: null,
        isPlanInCartRemoved: false
    },
    watch: {
        cart: {
            deep: true
        }
    },
    mutations: {
        setProducts(state, products) {
            state.products = products;
        },
        updatePlanInProducts(state, {id, price}) {
            state.products.forEach(function (item, index) {
                if (item.id === id) {
                    item.price = price;
                }
            });
        },
        addProductToCart(state, {id, productKey, isSubscription, requiresShipping}) {
            var productFoundInCart = false;

            state.cart.forEach(function (item, index) {
                if (item.productId === id) {
                    if (! item.isSubscription) {
                        item.qty++;
                        productFoundInCart = true;
                    }
                }
            });
            if ((! productFoundInCart) && (isSubscription)) {
                state.cart.forEach(function (item, index) {
                    if (item.isSubscription) {
                        item.productId = id;
                        item.productKey = productKey;
                        productFoundInCart = true;
                    }
                });
            }
            if (! productFoundInCart) {
                var productToAdd = {
                    productId: id,
                    productKey: productKey,
                    isSubscription: isSubscription,
                    requiresShipping: requiresShipping,
                    qty: 1
                };
                state.cart.push(productToAdd);
            }
        },
        removeProductFromCart(state, id) {
            for (var index = 0; index < state.cart.length; index++) {
                if (state.cart[index].productId === id) {
                    state.cart.splice(index, 1);
                    return;
                }
            }
        },
        updateProductQtyInCart(state, {id, qty}) {
            for (var index = 0; index < state.cart.length; index++) {
                if (state.cart[index].productId === id) {
                    if (! state.cart[index].isSubscription) {
                        state.cart[index].qty = Number(qty);
                    }
                    return;
                }
            }
        },
        removeAllProductsFromCart(state) {
            state.cart = [];
        },
        setStripeSourceDetails(state, {token, last4} ) {
            state.stripeSource = token;
            state.last4 = last4;
        },
        clearStripeSourceDetails(state) {
            state.stripeSource = null;
            state.last4 = null;
        },
        setShippingMethod(state, shippingMethod) {
            state.shippingMethod = shippingMethod;
        },
        setShippingAmount(state, shippingAmount) {
            state.shippingAmount = shippingAmount;
        },
        setLeadSource(state, leadSource) {
            state.leadSource = leadSource;
        },
        setIsFirstOrder(state, isFirstOrder) {
            state.isFirstOrder = isFirstOrder;
        },
        setDiscountCodeApplied(state, discountCodeApplied) {
            state.discountCodeApplied = discountCodeApplied;
        },
        setDiscountAmount(state, discountAmount) {
            state.discountAmount = discountAmount;
        },
        setShippingAddress(state, shippingAddress) {
            state.shippingAddress = shippingAddress;
        },
        setBillingAddress(state, billingAddress) {
            state.billingAddress = billingAddress;
        },
        setCurrentPlan(state, currentPlan) {
            state.currentPlan = currentPlan;

            var planInCartToRemoveId;
            for (var index = 0; index < state.cart.length; index++) {
                if (state.cart[index].isSubscription) {
                    state.products.forEach(function (item) {
                        if (item.id === state.cart[index].productId) {
                            if ((item.price < currentPlan.price) || (item.id === currentPlan.id)) {
                                planInCartToRemoveId = item.id;
                            }
                        }
                    });
                }
            }
            if (planInCartToRemoveId) {
                for (var jndex = 0; jndex < state.cart.length; jndex++) {
                    if (state.cart[jndex].productId === planInCartToRemoveId) {
                        state.cart.splice(jndex, 1);
                        state.isPlanInCartRemoved = true;
                        return;
                    }
                }
            }
        },
        clearIsPlanInCartRemoved(state) {
            state.isPlanInCartRemoved = false;
        }
    },
    actions: {
        fetchProducts({ commit }) {
            return axios.get(process.env.MIX_IDENTIVEW_API_BASEURL + 'products/?productType=consumer',
                { crossDomain: true })
                .then(response => {
                    commit('setProducts', response.data);
                });
        },
        async updatePlanInProducts({ commit, state }, {id, price}) {
            commit('updatePlanInProducts', {id, price});
        },
        addProductToCart({ commit, state }, id) {
            var product = state.products.find(product => product.id === id);
            commit('addProductToCart', {id, productKey: product.productKey, isSubscription: product.subscription, requiresShipping: product.requiresShipping})
        },
        removeProductFromCart({ commit, state}, id) {
            commit('removeProductFromCart', id);
            commit('setShippingAmount', 0);
        },
        updateProductQtyInCart({ commit, state}, {id, qty}) {
            commit('updateProductQtyInCart', {id, qty});
            commit('setShippingAmount', 0);
        },
        removeAllProductsFromCart({ commit, state}) {
            commit('removeAllProductsFromCart');
            commit('setShippingAmount', 0);
        },
        setStripeSourceDetails({ commit, state}, {token, last4}) {
            commit('setStripeSourceDetails', {token, last4})
        },
        clearStripeSourceDetails({ commit, state}) {
            commit('clearStripeSourceDetails')
        },
        setShippingMethod({ commit, state}, shippingMethod) {
            commit('setShippingMethod', shippingMethod)
        },
        setShippingAmount({ commit, state}, shippingAmount) {
            commit('setShippingAmount', shippingAmount)
        },
        setLeadSource({commit, state}, leadSource) {
            commit('setLeadSource', leadSource)
        },
        setIsFirstOrder({commit, state}, isFirstOrder) {
            commit('setIsFirstOrder', isFirstOrder)
        },
        setDiscountCodeApplied({ commit, state}, discountCodeApplied) {
            commit('setDiscountCodeApplied', discountCodeApplied)
        },
        setDiscountAmount({ commit, state}, discountAmount) {
            commit('setDiscountAmount', discountAmount)
        },
        setShippingAddress({ commit, state}, shippingAddress) {
            commit('setShippingAddress', shippingAddress)
        },
        setBillingAddress({ commit, state}, billingAddress) {
            commit('setBillingAddress', billingAddress)
        },
        async setCurrentPlan({ commit, state}, currentPlan) {
            await commit('setCurrentPlan', currentPlan);
            return state.isPlanInCartRemoved;
        },
        clearIsPlanInCartRemoved({commit, state}) {
            commit('clearIsPlanInCartRemoved');
        }
    },
    getters: {
        getProducts(state) {
            return state.products;
        },
        getProductById: (state) => (id) => {
            return state.products.find(product => product.id === id)
        },
        getCart(state) {
            return state.cart;
        },
        getNumOfItemsInCart(state) {
            var numOfItemsInCart = 0;
            state.cart.forEach(function (item, index) {
                numOfItemsInCart += item.qty;
            });
            return numOfItemsInCart;
        },
        getTotalCartAmount(state, getters) {
            var totalCartAmount = 0;
            state.cart.forEach(function (item, index) {
                var product = getters.getProductById(item.productId);
                totalCartAmount += product.price * item.qty;
            });
            return totalCartAmount;
        },
        getTotalOrderAmount(state, getters) {
            return getters.getTotalCartAmount + state.shippingAmount - state.discountAmount;
        },
        getStripeSource(state) {
            return state.stripeSource;
        },
        getShippingMethod(state) {
            return state.shippingMethod;
        },
        getShippingAddress(state) {
            return state.shippingAddress;
        },
        getBillingAddress(state) {
            return state.billingAddress;
        },
        getRequiresShipping(state) {
            for (let cartItem of state.cart) {
                if (cartItem.requiresShipping) {
                    return true;
                }
            }
            return false;
        },
        getPlanByProductKey: (state) => (productKey) => {
            return state.products.find(product => product.productKey === productKey)
        },
        getProductByProductKeyAndPlanKey: (state) => (productKey, planKey) => {
            if (planKey) {
                return state.products.find(product => (product.productKey === productKey && product.planSpecificKey === planKey))
            }
            else {
                return state.products.find(product => product.productKey === productKey)
            }
        },
        getCurrentPlan(state) {
            return state.currentPlan;
        },
        hasActivePlan(state) {
            return !!(state.currentPlan.id);
        },
        getPlanInCart(state) {
            for (let cartItem of state.cart) {
                if (cartItem.isSubscription) {
                    return cartItem;
                }
            }
            return null;
        },
        getLeadSource(state) {
            return state.leadSource;
        },
        getIsFirstOrder(state) {
            return state.isFirstOrder;
        },
    },
    plugins: [vuexLocal.plugin]
});
