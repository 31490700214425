<template>
    <div class="container pb-5 mb-1">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="row">
                    <div class="mt-5 offset-sm-4 col-sm-4">
                        <img class="mb-grid-gutter" src="/images/diamondeye-logo.png" alt="">
                    </div>
                </div>
                <template v-if="! this.isValid && !this.showMasterImage">
                    <div class="alert alert-danger" role="alert">
                        Not recognized as a valid Diamond ID Number
                    </div>
                </template>
                <form class="ml-3 needs-validation" novalidate v-if="!this.showMasterImage">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="company-id">Supplier Code</label>
                                <input class="form-control" type="text" v-model.trim="$v.companyIdInput.$model" id="company-id" :class="{ 'is-invalid': $v.companyIdInput.$error }">
                                <div class="invalid-feedback" v-if="!$v.companyIdInput.required">Please enter a supplier code</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="diamond-id">Diamond Identification Number</label>
                                <input class="form-control" type="text" v-model.trim="$v.diamondIdInput.$model" id="diamond-id" :class="{ 'is-invalid': $v.diamondIdInput.$error }">
                                <div class="invalid-feedback" v-if="!$v.diamondIdInput.required">Please enter a Diamond ID Number</div>
                            </div>
                        </div>
                        <div class="col-sm-12 text-center">
                            <div class="form-group">
                                <a class="btn btn-primary btn-lg" v-bind:class="{ disabled: isFormCompleted}" href="#" @click.prevent="loadDiamond()"><span class="d-none d-sm-inline">Load into my DiamondEye App</span><span class="d-inline d-sm-none">Load into my DiamondEye App</span></a>
                           </div>
                        </div>
                    </div>
                </form>
                <div class="ml-3" v-if="this.showMasterImage">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <img :src="this.masterImageURL" alt="Scan Master Image"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-4">
                    <div class="card-body">
                        <h3 class="card-title text-center">Download the App</h3>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <a href="https://apps.apple.com/id/app/diamondeyepro/id6479329165" class="market-btn apple-btn market-btn-light bg-dark" role="button">
                                    <span class="market-button-subtitle">Download on the</span>
                                    <span class="market-button-title">App Store</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 pb-3">
                    <div class="text-center col-md-4 offset-md-4">
                        <a href="/" class="btn btn-lg btn-primary">Learn more about DiamondEye</a>
                    </div>
                </div>
            </div>
       </div>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators';
    import feather from 'feather-icons';
    import router from "../router";
    export default {
        name: "MyDiamondPage",
        data: function () {
            return {
                isValid: true,
                showMasterImage: false,
                masterImageURL: null,
                companyIdInput: null,
                diamondIdInput: null
            }
        },
        validations: {
            companyIdInput: {
                required
            },
            diamondIdInput: {
                required
            }
        },
         created() {
             const routeParam = this.$route.params.diamondId;
             this.showMasterImage = !routeParam.includes('@');
             this.diamondIdInput = routeParam;

             if (routeParam && routeParam.includes('@')) {
                 // Split the parameter into company ID and diamond ID
                 const [companyId, diamondId] = routeParam.split('@');
                 this.companyIdInput = companyId;
                 this.diamondIdInput = diamondId;
             }

             if (!this.showMasterImage) {
                 const url = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'idscans/companyShortCode/' + this.companyIdInput + '/stoneID/' + this.diamondIdInput + '/');
                 axios.get(url,
                     {
                         crossDomain: true,
                         headers: {}
                     })
                     .then(response => {
                         this.isValid = true;
                         window.location.href = 'GoDiamondEye://' + this.companyIdInput + '.' + this.diamondIdInput;
                         //console.log(response.data.id);
                     })
                     .catch(error => {
                         this.isValid = false;
                         //this.companyIdInput = null;
                         //this.diamondIdInput = null;
                         //console.log(error.response.status);
                     });
             }
             else if (this.showMasterImage) {
                 this.masterImageURL = process.env.MIX_AWS_IDENTIVEW_IMAGES_BUKCET_BASEURL + this.diamondIdInput
             }
        },
        mounted() {
            feather.replace();
        },
        methods: {
            loadDiamond() {
                window.location.href = '/diamonds/' + this.companyIdInput + '@' + this.diamondIdInput;
            }
        },
        computed: {
            isFormCompleted() {
                return this.$v.$invalid;
            },
        },
    }
</script>

<style scoped>

</style>
