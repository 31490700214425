<template>
    <div class="offcanvas offcanvas-reverse" id="offcanvas-cart">
        <div class="offcanvas-header d-flex justify-content-between align-items-center">
            <h3 class="offcanvas-title">Your cart</h3>
            <button id="close-offcanvas-cart" class="close" type="button" data-dismiss="offcanvas" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="offcanvas-body">
            <div class="offcanvas-body-inner">
                <h2 v-if="numOfItemsInCart === 0" class="h6 mt-2 px-4 py-3 bg-secondary text-center">Your cart is empty</h2>
                <template v-else>
                    <div class="text-right"><a @click.stop="clearCart()" class="text-danger btn-sm pr-0" href="#"><i class="mr-1" data-feather="x" style="width: .85rem; height: .85rem;"></i>Clear cart</a></div>
                    <div class="widget widget-featured-entries pt-3">
                        <cart-item-small v-for="cartItem in cartItems" :key="cartItem.productId" :id="cartItem.productId" :qty="cartItem.qty"></cart-item-small>
                        <hr>
                        <div class="d-flex justify-content-between align-items-center py-3">
                            <div class="font-size-sm">
                                <span class="mr-2">Subtotal:</span>
                                <span class="font-weight-semibold text-dark">{{ totalCartAmount }}</span>
                            </div>
                            <router-link :to="{ name: 'cart'}" class="btn btn-outline-secondary btn-sm">Expand cart<i class="mr-2" data-feather="chevron-right"></i></router-link>
                        </div>
                        <router-link :to="{ name: 'checkout-details'}" class="btn btn-primary btn-sm btn-block"><i class="mr-1" data-feather="credit-card"></i>Checkout</router-link>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import CartItemSmall from "./CartItemSmall";
    import { formatAsCurrency } from '../helper';

    export default {
        name: "OffCanvasCart",
        components: {
            CartItemSmall
        },
        computed: {
            cartItems() {
                return this.$store.state.cart;
            },
            numOfItemsInCart() {
                return this.$store.getters.getNumOfItemsInCart;
            },
            totalCartAmount() {
                return formatAsCurrency(this.$store.getters.getTotalCartAmount);
            }
        },
        methods: {
            clearCart() {
                this.$store.dispatch('removeAllProductsFromCart');
            }
        },
        watch:{
            $route (to, from){
                if (to.meta.checkout) {
                    $("#close-offcanvas-cart").click();
                }
            }
        }
    }
</script>

<style scoped>

</style>
