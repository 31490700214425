var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      _vm._l(_vm.products, function(product) {
        return _c(
          "li",
          [
            _vm._v(
              "\n            " +
                _vm._s(product.description) +
                " - " +
                _vm._s(_vm.formatAsCurrency(product.price)) +
                "\n            "
            ),
            _c("buy-now-button", { attrs: { id: product.id } })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }