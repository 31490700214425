var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.addProductToCart()
          }
        }
      },
      [_vm._v("Buy Now")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }