<template>
    <footer>
        <div class="pt-5 pb-4" style="background-color: #1f1f1f;">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 text-center text-sm-left">
                        <div class="mb-4 mb-sm-0"><a class="d-inline-block" href="/"><img width="75" src="/images/logo.jpg" alt="DiamondEye"/></a>
                        </div>
                    </div>
                </div>
                <div class="row pt-4">
                    <div class="col-sm-6 text-center text-sm-left">
                        <ul class="list-inline font-size-sm">
                            <li class="list-inline-item mr-3"><a class="nav-link-inline nav-link-light" href="#">Support</a></li>
                            <li class="list-inline-item mr-3"><a class="nav-link-inline nav-link-light" href="/privacy">Privacy Policy</a></li>
                            <li class="list-inline-item mr-3"><a class="nav-link-inline nav-link-light" href="/terms-of-use">Terms of use</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-3" style="background-color: #1a1a1a;">
            <div class="container font-size-xs text-center" aria-label="Copyright"><span class="text-white opacity-60 mr-1">Copyright © GemEx Systems, Inc. {{ getCurrentYear() }}, All rights reserved.</span></div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "AppFooter",
        methods: {
            getCurrentYear() {
                return new Date().getFullYear();
            }
        }
    }
</script>

<style scoped>

</style>
