let auth0Domain = process.env.MIX_AUTH0_DOMAIN;
let auth0ClientId = process.env.MIX_AUTH0_CLIENT_ID;
let auth0Audience = process.env.MIX_AUTH0_AUDIENCE;

const authConfig = {
    "domain": auth0Domain,
    "clientId": auth0ClientId,
    "audience": auth0Audience
};

export default authConfig;

