<template>
    <BlockUI :message="loadingMessage">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </BlockUI>
</template>

<script>
    import { retrieveIdentiviewUserMixin } from '../mixins/identiview-user';

    export default {
        name: "Callback",
        mixins: [retrieveIdentiviewUserMixin],
        data() {
            return {
                loadingMessage: "Loading..."
            }
        },
        methods: {
            async handleLoginEvent(data) {
                this.$router.push(data.state.target || "/");
                if (data.loggedIn === true) {
                    await this.retrieveOrCreateIdentiviewUser(data.profile.sub);
                }
            },
        },
        created() {
            this.$auth.handleAuthentication();
        }
    }
</script>

<style scoped>

</style>
