<template>
    <div>
        <div class="page-title-wrapper" aria-label="Page title">
            <div class="container">
                <h1 class="page-title">Plan comparison</h1><span class="d-block mt-2 text-muted"></span>
                <hr class="mt-4">
            </div>
        </div>
        <div class="container pb-5 mb-2">
            <div class="d-flex justify-content-between">
                <div v-if="! isAuthenticated">
                    <h4>Select a plan and check out in the cart</h4>
                    <p>If you already have an account with us, please <a href="#" @click.prevent="login">sign in</a> first.</p>
                </div>
                <div v-else-if="! hasActivePlan">
                    <h4>Select a plan and check out in the cart</h4>
                    <p>You are not subscribed to an active plan. Please select one.</p>
                </div>
                <div v-else>
                    <h6>You are currently subscribed to the {{ currentPlan.description }}.</h6>
                </div>
                <div class="col-xl-2 col-md-3 pt-3 pt-md-0">
                    <router-link :to="{ name: 'checkout-details'}" class="btn btn-primary btn-block" v-bind:class="{ 'disabled': !enableCheckout }">Checkout</router-link>
                </div>
            </div>
            <div class="comparison-table">
                <table class="mt-3 table table-bordered">
                    <thead class="bg-secondary">
                        <tr>
                            <td>
                                <plan-comparison-header product-key="PLAN_SILVER"></plan-comparison-header>
                            </td>
                            <td>
                                <plan-comparison-header product-key="PLAN_GOLD"></plan-comparison-header>
                            </td>
                            <td>
                                <plan-comparison-header product-key="PLAN_DIAMOND"></plan-comparison-header>
                            </td>
                        </tr>
                    </thead>
                    <tbody id="summary" data-filter="target">
                        <tr class="bg-secondary">
                            <td><span class="text-dark font-weight-semibold">Free</span></td>
                            <td><span class="text-dark font-weight-semibold">$1 per month (billed annually)</span></td>
                            <td><span class="text-dark font-weight-semibold">$2 per month (billed annually)</span></td>
                        </tr>
                        <tr>
                            <td>Registration of one solitaire diamond included with DiamondEye kit</td>
                            <td>Registration of one solitaire diamond included with DiamondEye kit</td>
                            <td>Registration of one solitaire diamond included with DiamondEye kit</td>
                        </tr>
                        <tr>
                            <td>Verification: $10 each</td>
                            <td>Verification: 4 included per year</td>
                            <td>Verification: 10 included per year</td>
                        </tr>
                        <tr>
                            <td>Prong Check $4 each</td>
                            <td>Prong Check: 12 included per year</td>
                            <td>Prong Check: 24 included per year</td>
                        </tr>
                        <tr>
                            <td>Additional Diamond Registration: $40 per diamond</td>
                            <td>Additional Diamond Registration: $25 per diamond</td>
                            <td>Additional Diamond Registration: $20 per diamond</td>
                        </tr>
                        <tr>
                            <td>Register with the Missing/Lost Database: $50</td>
                            <td>Register with the Missing/Lost Database: $25</td>
                            <td>Register with the Missing/Lost Database: Free</td>
                        </tr>
                        <!--
                        <tr>
                            <td>Jewelry Insurance Discount: No</td>
                            <td><a href="https://my.jewelersmutual.com/jewelry-insurance-quote-apply/" target="_blank">Jewelry Insurance Discount with Jewelers Mutual</a></td>
                            <td><a href="https://my.jewelersmutual.com/jewelry-insurance-quote-apply/" target="_blank">Jewelry Insurance Discount with Jewelers Mutual</a></td>
                        </tr>
                        -->
                    </tbody>
                </table>
                <div class="card mt-5">
                    <div class="card-body">
                        <h3 class="card-title text-center">Download the App</h3>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <a href="https://apps.apple.com/id/app/diamondeyepro/id6479329165" class="market-btn apple-btn market-btn-light bg-dark" role="button">
                                    <span class="market-button-subtitle">Download on the</span>
                                    <span class="market-button-title">App Store</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PlanComparisonHeader from "../components/PlanComparisonHeader";
    export default {
        name: "PlanComparison",
        components: {PlanComparisonHeader},
        methods: {
            login() {
                this.$auth.login({ target: this.$route.path });
            },
            track() {

            }
        },
        mounted() {
            if (this.$route.query.selectPlan) {
                $("#close-offcanvas-cart").click();
                this.$modal.show('dialog', {
                    text: 'Please select a plan before checking out.',
                    buttons: [{
                        title: 'Close', class: 'btn btn-info'
                    }
                    ]
                });
            }
            this.$gtag.event('conversion', {
                'send_to': 'AW-1071484966/jMPkCP-et_UCEKag9v4D',
                'transaction_id': '',
                })
        },
        computed: {
            isAuthenticated() {
                return this.$auth.isAuthenticated();
            },
            hasActivePlan() {
                return this.$store.getters.hasActivePlan;
            },
            currentPlan() {
                return this.$store.getters.getCurrentPlan;
            },
            planInCart() {
                return this.$store.getters.getPlanInCart;
            },
            isCartEmpty() {
                return (this.$store.getters.getNumOfItemsInCart === 0);
            },
            enableCheckout() {
                return (!this.isCartEmpty && (this.planInCart || this.hasActivePlan))
            },
        }
    }
</script>

<style scoped>
    td {
        font-size: 1.25em;
    }
</style>
