<template>
    <router-link to="/diamondeye-viewer">
        <button type="button" class="btn btn-primary btn-lg rounded">{{ actionText }}<i class="ml-2" data-feather="arrow-right"></i></button>
    </router-link>
</template>

<script>
    export default {
        name: "StartHereButton",
        props: ['actionText']
    }
</script>

<style scoped>

</style>
