<template>
    <div>
        <div class="container pb-5 mb-1">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <h2 class="mt-5 mb-5 text-center">How It Works!</h2>

                    <div class="card">
                        <div class="card-body">
                            <h3 class="card-title text-center">Get started in a few simple steps:</h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="shopping-cart"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Order your Registration Kit</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="user"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Create your Account</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="check-circle"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Register your Diamond</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="text-center col-md-4 offset-md-4">
                                    <button type="button" class="btn btn-primary btn-lg rounded" @click.stop="addProductToCart()">Start Here<i class="ml-2" data-feather="arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5 pb-4">
                <div class="text-center col-md-4 offset-md-4">
                    <a href="https://gemex.com/diamondeye-user-guide-for-diamondeye-app" target="_blank" class="btn btn-primary px-5 mr-2"><i class="mr-2" data-feather="book-open"></i>How to Use DiamondEye</a>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-6">

                    <div class="mt-5 card">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/NmEZAeC-Qss?rel=0" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                            </iframe>
                        </div>
                        <div class="card-body">
                            <h5 class="text-center card-text">DiamondEye Kit</h5>
                        </div>
                    </div>

                    <div class="mt-5 card">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/fDWtdP2qWCg?rel=0" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                            </iframe>
                        </div>
                        <div class="card-body">
                            <h5 class="text-center card-text">DiamondEye Account and Registration</h5>
                        </div>
                    </div>

                    <div class="mt-5 card">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/vEewADCd570?rel=0" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                            </iframe>
                        </div>
                        <div class="card-body">
                            <h5 class="text-center card-text">Register Your Diamond</h5>
                        </div>
                    </div>

                    <div class="mt-5 card">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/ZZWBWr1B8Ss?rel=0" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                            </iframe>
                        </div>
                        <div class="card-body">
                            <h5 class="text-center card-text">Verify Your Diamond</h5>
                        </div>
                    </div>

                    <div class="mt-5 card">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/HZEFqmmdVNY?rel=0" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                            </iframe>
                        </div>
                        <div class="card-body">
                            <h5 class="text-center card-text">See Your Diamond</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import feather from 'feather-icons';
    import StartHereButton from "../components/StartHereButton";
    import router from "../router";

    export default {
        name: "HowItWorks",
        components: {StartHereButton},
        methods: {
            addProductToCart() {
                this.$store.dispatch('addProductToCart', this.DIAMOND_VIEWER_KIT_PRODUCT_ID);
                this.$modal.show('dialog', {
                    text: 'A Viewer Kit has been added to your cart.',
                    buttons: [{
                        title: 'Close',
                        class: 'btn btn-info'
                    }
                    ]
                });
                router.push({ path: 'plan-comparison' });
            },
        },
        created() {
            this.DIAMOND_VIEWER_KIT_PRODUCT_ID = 1;
        },
        mounted() {
            feather.replace();
        }
    }
</script>

<style scoped>
    a[aria-expanded=true] .plus-circle {
        display: none;
    }
    a[aria-expanded=false] .minus-circle {
        display: none;
    }

    .card-header-text {
        margin-bottom: 2px;
        display: inline;
    }
</style>
