<template>
    <div class="page-title-wrapper" aria-label="Page title">
        <div class="container">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="mt-n1 mr-1"><i data-feather="home"></i></li>
                    <li class="breadcrumb-item"><a href="index.html">Home</a>
                    </li>
                    <li class="breadcrumb-item"><a href="#">Shop</a>
                    </li>
                </ol>
            </nav>
            <h1 class="page-title">{{ pageTitle }}</h1><span class="d-block mt-2 text-muted"></span>
        </div>
    </div>
</template>

<script>
    import feather from 'feather-icons';

    export default {
        name: "BreadCrumb",
        props: [ 'pageTitle' ],
        mounted() {
            feather.replace();
        }
    }
</script>

<style scoped>

</style>
