<template>
    <div class="media">
        <div class="featured-entry-thumb mr-3">
            <a href="#"><img :src="'images/' + productImage()" width="64" alt="Product thumb"></a>
            <span @click.stop="removeCartItem()" class="item-remove-btn"><i data-feather="x"></i></span>
        </div>
        <div class="media-body">
            <h6 class="featured-entry-title"><a href="#">{{ productInCart.description }}</a></h6>
            <p class="featured-entry-meta">{{ qty }} <span class='text-muted'>x</span> {{ formatAsCurrency(productInCart.price) }}</p>
        </div>
    </div>
</template>

<script>
    import { formatAsCurrency } from '../helper';
    import feather from 'feather-icons';

    export default {
        name: "CartItemSmall",
        props: [ 'id', 'qty' ],
        computed: {
            productInCart() {
                return this.$store.getters.getProductById(this.id);
            }
        },
        methods: {
            removeCartItem() {
                this.$store.dispatch('removeProductFromCart', this.id);
            },
            formatAsCurrency(value) {
                return formatAsCurrency(value);
            },
            productImage() {
                return 'product-' + this.id + '.jpg';
            }
        },
        mounted() {
            feather.replace();
        }
    }
</script>

<style scoped>

</style>
