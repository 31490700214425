<template>
    <div class="container pb-5 mb-sm-4">
        <div class="pt-5">
            <div class="card py-3 mt-sm-3">
                <div class="card-body text-center">
                    <h3 class="h4 pb-3">Thank you for your order!</h3>
                    <p v-if="requiresShipping" class="mb-2">Your order has been placed. Physical product(s) will be processed and shipped within 1-2 business days.<br/> If you ordered any digital products or a subscription, they should now be availabe in your DiamondEye account.</p>
                    <p v-else class="mb-2">Your digital products or subscription has been processed and should now be available in your DiamoneEye account.</p>
                    <p class="mb-2">Make sure you make note of your order number, which is <strong>{{ orderNumber }}.</strong></p>
                    <p>You will be receiving an email shortly with confirmation of your order.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { retrieveIdentiviewUserMixin } from '../mixins/identiview-user';

    export default {
        name: "CheckoutCompletePage",
        mixins: [retrieveIdentiviewUserMixin],
        props: [ 'orderNumber', 'requiresShipping' ],
        async mounted() {
            this.$store.dispatch('removeAllProductsFromCart');
            this.$store.dispatch('clearStripeSourceDetails');
            this.$store.dispatch('setShippingAmount', 0);
            this.$store.dispatch('setDiscountCodeApplied', null);
            this.$store.dispatch('setDiscountAmount', 0);
            this.$store.dispatch('clearIsPlanInCartRemoved');

            await this.retrieveOrCreateIdentiviewUser(this.$auth.profile.sub);

            this.$gtag.event('conversion', {
                'send_to': 'AW-1071484966/g-y_CKukjvUCEKag9v4D',
                'transaction_id': this.orderNumber,
            })
        }
    }
</script>

<style scoped>

</style>
