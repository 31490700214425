var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bread-crumb", { attrs: { pageTitle: "Checkout" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container pb-5 mb-sm-4 mt-n2 mt-md-n3" },
        [
          _c("checkout-steps", { attrs: { stepNumber: 2 } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row pt-4 mt-2" },
            [
              _c("div", { staticClass: "col-xl-9 col-md-8" }, [
                this.isFirstOrder
                  ? _c("span", [
                      _c("h2", { staticClass: "h6 px-4 py-3 bg-secondary" }, [
                        _vm._v("How did you hear about us?")
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "ml-3 needs-validation",
                          attrs: { novalidate: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.leadSource,
                                        expression: "leadSource"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "lead-source" },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.leadSource = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function($event) {
                                          return _vm.handleLeadSourceChange()
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "Google Search" } },
                                      [_vm._v("Google Search")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { attrs: { value: "YouTube" } },
                                      [_vm._v("YouTube")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { attrs: { value: "From a Friend" } },
                                      [_vm._v("From a Friend")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { attrs: { value: "From my Jeweler" } },
                                      [_vm._v("From my Jeweler")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { attrs: { value: "Other" } },
                                      [_vm._v("Other")]
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            this.showLeadSourceTextInput
                              ? _c("div", { staticClass: "col-sm-9" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.otherLeadSource,
                                          expression: "otherLeadSource",
                                          modifiers: { trim: true }
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.otherLeadSource.$error
                                      },
                                      attrs: {
                                        rows: "3",
                                        type: "text",
                                        id: "lead-source-text"
                                      },
                                      domProps: { value: _vm.otherLeadSource },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.otherLeadSource = $event.target.value.trim()
                                        },
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    !_vm.$v.otherLeadSource.required
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "Please specify how you heard about us."
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h2", { staticClass: "h6 px-4 py-3 bg-secondary" }, [
                  _vm._v("Enter your information")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "ml-3 needs-validation",
                    attrs: { novalidate: "" }
                  },
                  [
                    _c("h6", { staticClass: "mb-3 pt-4 pb-3 border-bottom" }, [
                      _vm._v("Billing address")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "checkout-billing-firstname" } },
                            [_vm._v("First Name")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.billingAddress.firstName.$model,
                                expression:
                                  "$v.billingAddress.firstName.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.billingAddress.firstName.$error
                            },
                            attrs: {
                              type: "text",
                              maxlength: "50",
                              id: "checkout-billing-firstname"
                            },
                            domProps: {
                              value: _vm.$v.billingAddress.firstName.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.billingAddress.firstName,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.firstName.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter a first name")
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "checkout-billing-lastname" } },
                            [_vm._v("Last Name")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.billingAddress.lastName.$model,
                                expression: "$v.billingAddress.lastName.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.billingAddress.lastName.$error
                            },
                            attrs: {
                              type: "text",
                              maxlength: "50",
                              id: "checkout-billing-lastname"
                            },
                            domProps: {
                              value: _vm.$v.billingAddress.lastName.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.billingAddress.lastName,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.lastName.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter a last name")
                              ])
                            : _vm._e()
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "checkout-billing-email" } },
                            [_vm._v("E-mail Address")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value:
                                  _vm.$v.billingAddress.emailAddress.$model,
                                expression:
                                  "$v.billingAddress.emailAddress.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.billingAddress.emailAddress.$error
                            },
                            attrs: {
                              type: "text",
                              maxlength: "100",
                              id: "checkout-billing-email"
                            },
                            domProps: {
                              value: _vm.$v.billingAddress.emailAddress.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.billingAddress.emailAddress,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.emailAddress.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter an email address")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.emailAddress.email
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter a valid email address")
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "checkout-billing-phone" } },
                            [_vm._v("Phone Number")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.billingAddress.phone.$model,
                                expression: "$v.billingAddress.phone.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.billingAddress.phone.$error
                            },
                            attrs: {
                              type: "text",
                              maxlength: "15",
                              id: "checkout-billing-phone"
                            },
                            domProps: {
                              value: _vm.$v.billingAddress.phone.$model
                            },
                            on: {
                              keyup: function($event) {
                                return _vm.formatPhone($event, "billing")
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.billingAddress.phone,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.phone.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter a phone number")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.phone.validPhone
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter a valid phone number")
                              ])
                            : _vm._e()
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "checkout-billing-address-1" } },
                            [_vm._v("Address 1")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.billingAddress.address1.$model,
                                expression: "$v.billingAddress.address1.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.billingAddress.address1.$error
                            },
                            attrs: {
                              type: "text",
                              maxlength: "100",
                              id: "checkout-billing-address-1"
                            },
                            domProps: {
                              value: _vm.$v.billingAddress.address1.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.billingAddress.address1,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.address1.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter an address")
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "checkout-billing-address-2" } },
                            [_vm._v("Address 2")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.billingAddress.address2,
                                expression: "billingAddress.address2",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              maxlength: "100",
                              id: "checkout-billing-address-2"
                            },
                            domProps: { value: _vm.billingAddress.address2 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.billingAddress,
                                  "address2",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "checkout-billing-city" } },
                            [_vm._v("City")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.billingAddress.city.$model,
                                expression: "$v.billingAddress.city.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.billingAddress.city.$error
                            },
                            attrs: {
                              type: "text",
                              maxlength: "100",
                              id: "checkout-billing-city"
                            },
                            domProps: {
                              value: _vm.$v.billingAddress.city.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.billingAddress.city,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.city.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter a city")
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "checkout-billing-state" } },
                              [_vm._v("State")]
                            ),
                            _vm._v(" "),
                            _c("region-select", {
                              staticClass: "form-control custom-select",
                              attrs: {
                                maxlength: "100",
                                id: "checkout-billing-state",
                                country: _vm.$v.billingAddress.country.$model,
                                region: _vm.$v.billingAddress.state.$model
                              },
                              model: {
                                value: _vm.$v.billingAddress.state.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.billingAddress.state,
                                    "$model",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "$v.billingAddress.state.$model"
                              }
                            }),
                            _vm._v(" "),
                            !_vm.$v.billingAddress.state.required
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v("Please select a state")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "checkout-billing-zip" } },
                            [_vm._v("Postal Code")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.billingAddress.postalcode.$model,
                                expression:
                                  "$v.billingAddress.postalcode.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.$v.billingAddress.postalcode.$error
                            },
                            attrs: {
                              type: "text",
                              maxlength: "12",
                              id: "checkout-billing-zip"
                            },
                            domProps: {
                              value: _vm.$v.billingAddress.postalcode.$model
                            },
                            on: {
                              keyup: function($event) {
                                return _vm.formatPostalCode($event, "billing")
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.billingAddress.postalcode,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.postalcode.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter a postal code")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.billingAddress.postalcode.validPostalCode
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("Please enter a valid postal code")
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "checkout-billing-country" } },
                              [_vm._v("Country")]
                            ),
                            _vm._v(" "),
                            _c("country-select", {
                              staticClass: "form-control custom-select",
                              attrs: {
                                id: "checkout-billing-country",
                                country: _vm.$v.billingAddress.country.$model,
                                topCountry: "US"
                              },
                              model: {
                                value: _vm.$v.billingAddress.country.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.billingAddress.country,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.billingAddress.country.$model"
                              }
                            }),
                            _vm._v(" "),
                            !_vm.$v.billingAddress.country.required
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v("Please select a country")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { class: { "d-none": !_vm.requiresShipping } }, [
                      _c(
                        "h6",
                        { staticClass: "mb-3 pt-2 pb-3 border-bottom" },
                        [_vm._v("Shipping address")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6 mb-3" }, [
                          _c(
                            "div",
                            { staticClass: "custom-control custom-checkbox" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.isSameAsBilling,
                                    expression: "isSameAsBilling"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: { type: "checkbox", id: "same-address" },
                                domProps: {
                                  checked: Array.isArray(_vm.isSameAsBilling)
                                    ? _vm._i(_vm.isSameAsBilling, null) > -1
                                    : _vm.isSameAsBilling
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.isSameAsBilling,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.isSameAsBilling = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.isSameAsBilling = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.isSameAsBilling = $$c
                                      }
                                    },
                                    function($event) {
                                      return _vm.sameAsBilling()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "same-address" }
                                },
                                [_vm._v("Same as billing address")]
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "collapse",
                          attrs: { id: "shipping-address-panel" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkout-firstname" } },
                                  [_vm._v("First Name")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.shippingAddress.firstName.$model,
                                      expression:
                                        "$v.shippingAddress.firstName.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shippingAddress.firstName.$error
                                  },
                                  attrs: {
                                    type: "text",
                                    maxlength: "50",
                                    id: "checkout-firstname"
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.shippingAddress.firstName.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.shippingAddress.firstName,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.firstName.required
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v("Please enter a first name")]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkout-lastname" } },
                                  [_vm._v("Last Name")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.shippingAddress.lastName.$model,
                                      expression:
                                        "$v.shippingAddress.lastName.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shippingAddress.lastName.$error
                                  },
                                  attrs: {
                                    type: "text",
                                    maxlength: "50",
                                    id: "checkout-lastname"
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.shippingAddress.lastName.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.shippingAddress.lastName,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.lastName.required
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v("Please enter a last name")]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkout-email" } },
                                  [_vm._v("E-mail Address")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.shippingAddress.emailAddress
                                          .$model,
                                      expression:
                                        "$v.shippingAddress.emailAddress.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shippingAddress.emailAddress.$error
                                  },
                                  attrs: {
                                    type: "text",
                                    maxlength: "100",
                                    id: "checkout-email"
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.shippingAddress.emailAddress.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.shippingAddress.emailAddress,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.emailAddress.required
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v("Please enter an email address")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.emailAddress.email
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "Please enter a valid email address"
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkout-phone" } },
                                  [_vm._v("Phone Number")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.shippingAddress.phone.$model,
                                      expression:
                                        "$v.shippingAddress.phone.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shippingAddress.phone.$error
                                  },
                                  attrs: {
                                    type: "text",
                                    maxlength: "15",
                                    id: "checkout-phone"
                                  },
                                  domProps: {
                                    value: _vm.$v.shippingAddress.phone.$model
                                  },
                                  on: {
                                    keyup: function($event) {
                                      return _vm.formatPhone($event, "shipping")
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.shippingAddress.phone,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.phone.required
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v("Please enter a phone number")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.phone.validPhone
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "Please enter a valid phone number"
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkout-address-1" } },
                                  [_vm._v("Address 1")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.shippingAddress.address1.$model,
                                      expression:
                                        "$v.shippingAddress.address1.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shippingAddress.address1.$error
                                  },
                                  attrs: {
                                    type: "text",
                                    maxlength: "100",
                                    id: "checkout-address-1"
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.shippingAddress.address1.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.shippingAddress.address1,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.address1.required
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v("Please enter an address")]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkout-address-2" } },
                                  [_vm._v("Address 2")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.shippingAddress.address2,
                                      expression: "shippingAddress.address2",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    maxlength: "100",
                                    id: "checkout-address-2"
                                  },
                                  domProps: {
                                    value: _vm.shippingAddress.address2
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shippingAddress,
                                        "address2",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkout-city" } },
                                  [_vm._v("City")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.$v.shippingAddress.city.$model,
                                      expression:
                                        "$v.shippingAddress.city.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shippingAddress.city.$error
                                  },
                                  attrs: {
                                    type: "text",
                                    maxlength: "100",
                                    id: "checkout-city"
                                  },
                                  domProps: {
                                    value: _vm.$v.shippingAddress.city.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.shippingAddress.city,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.city.required
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v("Please enter a city")]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "checkout-state" } },
                                    [_vm._v("State")]
                                  ),
                                  _vm._v(" "),
                                  _c("region-select", {
                                    staticClass: "form-control custom-select",
                                    attrs: {
                                      maxlength: "100",
                                      id: "checkout-state",
                                      country:
                                        _vm.$v.shippingAddress.country.$model,
                                      region:
                                        _vm.$v.shippingAddress.state.$model
                                    },
                                    model: {
                                      value:
                                        _vm.$v.shippingAddress.state.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.shippingAddress.state,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.shippingAddress.state.$model"
                                    }
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.shippingAddress.state.required
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [_vm._v("Please select a state")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkout-zip" } },
                                  [_vm._v("Postal Code")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.shippingAddress.postalcode
                                          .$model,
                                      expression:
                                        "$v.shippingAddress.postalcode.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.shippingAddress.postalcode.$error
                                  },
                                  attrs: {
                                    type: "text",
                                    maxlength: "12",
                                    id: "checkout-zip"
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.shippingAddress.postalcode.$model
                                  },
                                  on: {
                                    keyup: function($event) {
                                      return _vm.formatPostalCode(
                                        $event,
                                        "shipping"
                                      )
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.shippingAddress.postalcode,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.postalcode.required
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v("Please enter a postal code")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.shippingAddress.postalcode
                                  .validPostalCode
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "Please enter a valid postal code"
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "checkout-country" } },
                                    [_vm._v("Country")]
                                  ),
                                  _vm._v(" "),
                                  _c("country-select", {
                                    staticClass: "form-control custom-select",
                                    attrs: {
                                      id: "checkout--country",
                                      country:
                                        _vm.$v.shippingAddress.country.$model,
                                      topCountry: "US"
                                    },
                                    model: {
                                      value:
                                        _vm.$v.shippingAddress.country.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.shippingAddress.country,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.shippingAddress.country.$model"
                                    }
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.shippingAddress.country.required
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [_vm._v("Please select a country")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ])
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex pt-4 mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "w-50 pr-3" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-outline-secondary btn-block",
                          attrs: { to: { name: "cart" } }
                        },
                        [
                          _c("i", {
                            staticClass: "mr-1",
                            attrs: { "data-feather": "chevron-left" }
                          }),
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Back to cart")
                          ]),
                          _c("span", { staticClass: "d-inline d-sm-none" }, [
                            _vm._v("Back")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-50 pl-2" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary btn-block",
                        class: {
                          disabled:
                            _vm.isMissingCheckoutDetails ||
                            _vm.isOtherLeadSourceMissing
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveCheckoutDetails()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "d-none d-sm-inline" }, [
                          _vm._v(_vm._s(_vm.nextStepLabel))
                        ]),
                        _c("span", { staticClass: "d-inline d-sm-none" }, [
                          _vm._v("Next")
                        ]),
                        _c("i", {
                          staticClass: "ml-1",
                          attrs: { "data-feather": "chevron-right" }
                        })
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("checkout-order-summary", {
                attrs: { "show-promo-code-form": false }
              }),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "needs-validation modal fade",
                  attrs: {
                    "data-backdrop": "static",
                    "data-keyboard": "false",
                    method: "post",
                    id: "order-viewer-kit",
                    tabindex: "-1",
                    novalidate: ""
                  }
                },
                [
                  _c("div", { staticClass: "modal-dialog modal-lg" }, [
                    _c("div", { staticClass: "modal-content" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.addProductToCart()
                                }
                              }
                            },
                            [_vm._v("Add the Viewer to My Cart")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-info mr-2 mt-0",
                              attrs: { type: "button", "data-dismiss": "modal" }
                            },
                            [_vm._v("I already have a Viewer")]
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Alert")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("p", [
            _vm._v(
              "Please note that you will need a DiamondEye Viewer to use with the subscription."
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }