<template>
    <div class="d-sm-flex justify-content-between my-4 pb-4 border-bottom">
        <div class="media d-block d-sm-flex text-center text-sm-left"><a class="cart-item-thumb mx-auto mr-sm-4" href="#"><img :src="'images/' + productImage()" alt="Product"></a>
            <div class="media-body pt-3">
                <h3 class="product-card-title font-weight-semibold border-0 pb-0"><a href="shop-single-apparel.html">{{ productInCart.description }}</a></h3>
                <div class="font-size-lg text-primary pt-2">{{ formatAsCurrency(productInCart.price) }}</div>
            </div>
        </div>
        <template v-if="updatable">
            <div class="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left" style="max-width: 10rem;">
                <div class="form-group mb-2">
                    <label for="quantity1">Quantity</label>
                    <input class="form-control form-control-sm" v-model="itemQty" @change="updateCartItemQty" type="number" id="quantity1" step="1" min="1">
                </div>
                <button @click.stop="removeCartItem()" class="btn btn-outline-danger btn-sm btn-block mb-2" type="button"><i class="mr-1" data-feather="trash-2"></i>Remove</button>
            </div>
        </template>
        <template v-else>
            <div class="pt-2 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left" style="max-width: 10rem;">
                <div class="font-size-sm mb-3"><span class="text-muted mr-2">Quantity:</span><span class="font-weight-semibold">{{ qty }}</span></div>
                <button class="btn btn-outline-secondary btn-sm btn-block mb-2" type="button"><i class="mr-1" data-feather="edit-2"></i>Edit</button>
            </div>
        </template>
    </div>
</template>

<script>
    import { formatAsCurrency } from '../helper';

    export default {
        name: "CartItem",
        props: [ 'id', 'qty', 'updatable' ],
        data: function() {
            return {
                itemQty: this.qty
            }
        },
        computed: {
            productInCart() {
                return this.$store.getters.getProductById(this.id);
            }
        },
        methods: {
            removeCartItem() {
                this.$store.dispatch('removeProductFromCart', this.id);
            },
            formatAsCurrency(value) {
                return formatAsCurrency(value);
            },
            updateCartItemQty(event) {
                if (this.itemQty <= 0) {
                    this.itemQty = 1;
                }
                this.$store.dispatch("updateProductQtyInCart", {id: this.id, qty: this.itemQty});
            },
            productImage() {
                return 'product-' + this.id + '.jpg';
            }
        }
    }
</script>

<style scoped>

</style>
