var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container pb-5 mb-1" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-lg-9" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-4 my-4" }, [
            _c("div", { staticClass: "text-center col-md-4 offset-md-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary px-5 mr-2",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.addProductToCart()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "mr-2",
                    attrs: { "data-feather": "shopping-cart" }
                  }),
                  _vm._v("Order Your Kit")
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "card mt-5" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h3", { staticClass: "card-title text-center" }, [
                _vm._v("Get started in a few simple steps:")
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "text-center col-md-4 offset-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary px-5 mr-2",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.addProductToCart()
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "mr-2",
                        attrs: { "data-feather": "shopping-cart" }
                      }),
                      _vm._v("Order Your Kit")
                    ]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(4)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "mt-4 text-center" }, [
      _vm._v("The DiamondEye"),
      _c("sup", [_vm._v("®")]),
      _vm._v(" Viewer")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-sm-8 card border-0" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("ul", [
              _c("li", { staticClass: "mb-2" }, [
                _vm._v("Your registration kit includes a DiamondEye Viewer​")
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-2" }, [
                _vm._v(
                  "The specialized DiamondEye Viewer uses the autofocus of the front camera to record the characteristics of the diamond"
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-2" }, [
                _vm._v(
                  "Use the DiamondEye Viewer with your phone/tablet to register your diamond on the SkyVault™ database.​"
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-2" }, [
                _vm._v(
                  "At any time, instantly verify your diamond.  DiamondEye will also confirm that the diamond is secure in your diamond setting.​​"
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4 mt-4" }, [
        _c("img", {
          staticClass: "mb-grid-gutter viewer-image",
          attrs: { src: "images/diamondeye-viewer.jpg", alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row pb-2 mt-4" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("img", {
          staticClass: "mb-grid-gutter",
          attrs: { src: "images/diamondeye-viewer6.jpg", alt: "" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("img", {
          staticClass: "mb-grid-gutter",
          attrs: { src: "images/diamondeye-viewer5.jpg", alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "shopping-cart" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Order your Registration Kit")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "user" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Create your Account")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "check-circle" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Register your Diamond")
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row pt-5" }, [
      _c("div", { staticClass: "text-center col-md-4 offset-md-4" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary px-5 mr-2",
            attrs: {
              href:
                "https://gemex.com/diamondeye-user-guide-for-diamondeye-app/",
              target: "_blank"
            }
          },
          [
            _c("i", {
              staticClass: "mr-2",
              attrs: { "data-feather": "book-open" }
            }),
            _vm._v("How to Use DiamondEye")
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }