<template>
    <div>
        <div class="container pb-5 mb-1">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <h2 class="mt-4 text-center">The DiamondEye<sup>&reg;</sup> Viewer</h2>
                    <div class="row mt-4 my-4">
                        <div class="text-center col-md-4 offset-md-4">
                            <button class="btn btn-primary px-5 mr-2" @click.stop="addProductToCart()"><i class="mr-2" data-feather="shopping-cart"></i>Order Your Kit</button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-8 card border-0">
                            <div class="row no-gutters">
                                <div class="card-body">
                                    <ul>
                                        <li class="mb-2">Your registration kit includes a DiamondEye Viewer​</li>
                                        <li class="mb-2">The specialized DiamondEye Viewer uses the autofocus of the front camera to record the characteristics of the diamond</li>
                                        <li class="mb-2">Use the DiamondEye Viewer with your phone/tablet to register your diamond on the SkyVault&#153; database.​</li>
                                        <li class="mb-2">At any time, instantly verify your diamond.  DiamondEye will also confirm that the diamond is secure in your diamond setting.​​</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-4"><img class="mb-grid-gutter viewer-image" src="images/diamondeye-viewer.jpg" alt=""></div>
                    </div>
                    <div class="row pb-2 mt-4">
                        <div class="col-sm-6"><img class="mb-grid-gutter" src="images/diamondeye-viewer6.jpg" alt=""></div>
                        <div class="col-sm-6"><img class="mb-grid-gutter" src="images/diamondeye-viewer5.jpg" alt=""></div>
                    </div>
                    <div class="card mt-5">
                        <div class="card-body">
                            <h3 class="card-title text-center">Get started in a few simple steps:</h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="shopping-cart"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Order your Registration Kit</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="user"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Create your Account</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="icon-box d-flex align-items-center text-center">
                                        <div class="icon-box-icon my-4 mr-3">
                                            <i data-feather="check-circle"></i>
                                        </div>
                                        <div class="icon-box-body">
                                            <h3 class="icon-box-title">Register your Diamond</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="text-center col-md-4 offset-md-4">
                                    <button class="btn btn-primary px-5 mr-2" @click.stop="addProductToCart()"><i class="mr-2" data-feather="shopping-cart"></i>Order Your Kit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-5">
                        <div class="text-center col-md-4 offset-md-4">
                            <a href="https://gemex.com/diamondeye-user-guide-for-diamondeye-app/" target="_blank" class="btn btn-primary px-5 mr-2"><i class="mr-2" data-feather="book-open"></i>How to Use DiamondEye</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import router from './../router';
    import feather from 'feather-icons';
    import StartHereButton from "../components/StartHereButton";

    export default {
        name: "DiamondEyeViewerPage",
        components: {StartHereButton},
        mounted() {
            feather.replace();
        },
        methods: {
            addProductToCart() {
                this.$store.dispatch('addProductToCart', this.DIAMOND_VIEWER_KIT_PRODUCT_ID);
                this.$modal.show('dialog', {
                    text: 'A Viewer Kit has been added to your cart.',
                    buttons: [{
                        title: 'Close',
                        class: 'btn btn-info'
                    }
                    ]
                });
                router.push({ path: 'plan-comparison' });
            }
        },
        created() {
            this.DIAMOND_VIEWER_KIT_PRODUCT_ID = 1;
        }
    }
</script>

<style scoped>
    .viewer-image {
        height: 280px;
        max-height: 100%
    }

</style>
