var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container pb-5 mb-1" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-lg-9" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "card mt-5" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h3", { staticClass: "card-title text-center" }, [
                _vm._v("Get started in a few simple steps:")
              ]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "text-center col-md-4 offset-md-4" },
                  [_c("StartHereButton")],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(3)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-sm-4 mt-4" }, [
        _c("img", {
          staticClass: "mb-grid-gutter",
          attrs: { src: "images/why-image1.jpg", alt: "" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-8 card border-0" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("Verify and Protect your diamonds")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    Register your diamond in the SkyVault database using the DiamondEye App, the Viewer and your phone or tablet.\n                                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    Option 1: Use Prong Check on the App.  DiamondEye will compare the prongs and the diamond security to your previous registration.  You will be notified within 1 day on the status of your prongs.\n                                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    Option 2: Verify your diamond using the same process.  The DiamondEye algorithm will compare and confirm the verification diamond is the same as the original.  You will receive verification within minutes.\n                                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    DiamondEye uses automated system review and expert review to confirm that your prongs are secure and your diamond is the same.\n                                "
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-8 card border-0" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("The DiamondEye Process")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    Register your diamond in the SkyVault database using the DiamondEye App, the Viewer and your phone or tablet.\n                                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    Option 1: Use Prong Check on the App.  DiamondEye will compare the prongs and the diamond security to your previous registration.  You will be notified within 1 day on the status of your prongs.\n                                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    Option 2: Verify your diamond using the same process.  The DiamondEye algorithm will compare and confirm the verification diamond is the same as the original.  You will receive verification within minutes.\n                                "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "\n                                    DiamondEye uses automated system review and expert review to confirm that your prongs are secure and your diamond is the same.\n                                "
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "shopping-cart" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Order your Registration Kit")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "user" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Create your Account")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "div",
          { staticClass: "icon-box d-flex align-items-center text-center" },
          [
            _c("div", { staticClass: "icon-box-icon my-4 mr-3" }, [
              _c("i", { attrs: { "data-feather": "check-circle" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "icon-box-body" }, [
              _c("h3", { staticClass: "icon-box-title" }, [
                _vm._v("Register your Diamond")
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card mt-5" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h3", { staticClass: "card-title text-center" }, [
          _vm._v("Download the App")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 text-center" }, [
            _c(
              "a",
              {
                staticClass: "market-btn apple-btn market-btn-light bg-dark",
                attrs: {
                  href:
                    "https://apps.apple.com/id/app/diamondeyepro/id6479329165",
                  role: "button"
                }
              },
              [
                _c("span", { staticClass: "market-button-subtitle" }, [
                  _vm._v("Download on the")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "market-button-title" }, [
                  _vm._v("App Store")
                ])
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }