var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bread-crumb", { attrs: { pageTitle: "Checkout" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container pb-5 mb-sm-4 mt-n2 mt-md-n3" },
        [
          _c("checkout-steps", { attrs: { stepNumber: 3 } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row pt-4 mt-2" },
            [
              _c("div", { staticClass: "col-xl-9 col-md-8" }, [
                _c("h2", { staticClass: "h6 px-4 py-3 bg-secondary mb-4" }, [
                  _vm._v("Choose shipping method")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table table-hover" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm.isUSShippingAddress
                          ? [
                              _c("tr", [
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-radio mb-4"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.shippingMethod,
                                            expression: "shippingMethod"
                                          }
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "radio",
                                          id: "default-shipping",
                                          name: "shipping-method",
                                          value: "0"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.shippingMethod,
                                            "0"
                                          )
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              _vm.shippingMethod = "0"
                                            },
                                            _vm.saveShippingMethod
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "default-shipping" }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._m(1),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v("5 - 7 days")
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v("$9.30")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-radio mb-4"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.shippingMethod,
                                            expression: "shippingMethod"
                                          }
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "radio",
                                          id: "fedex-express",
                                          name: "shipping-method",
                                          value: "1"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.shippingMethod,
                                            "1"
                                          )
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              _vm.shippingMethod = "1"
                                            },
                                            _vm.saveShippingMethod
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "fedex-express" }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._m(2),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v("3 - 4 days")
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v("$12.00")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-radio mb-4"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.shippingMethod,
                                            expression: "shippingMethod"
                                          }
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "radio",
                                          id: "fedex-2day",
                                          name: "shipping-method",
                                          value: "2"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.shippingMethod,
                                            "2"
                                          )
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              _vm.shippingMethod = "2"
                                            },
                                            _vm.saveShippingMethod
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "fedex-2day" }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._m(3),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v("2 days")
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v("$25.00")
                                ])
                              ])
                            ]
                          : [
                              _c("tr", [
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-radio mb-4"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.shippingMethod,
                                            expression: "shippingMethod"
                                          }
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "radio",
                                          id: "usps-international",
                                          name: "shipping-method",
                                          value: "3"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.shippingMethod,
                                            "3"
                                          )
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              _vm.shippingMethod = "3"
                                            },
                                            _vm.saveShippingMethod
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "usps-international" }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._m(4),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v("Varies by Country")
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v("$39.90")
                                ])
                              ])
                            ]
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex pt-2" }, [
                  _c(
                    "div",
                    { staticClass: "w-50 pr-3" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-outline-secondary btn-block",
                          attrs: { to: { name: "checkout-details" } }
                        },
                        [
                          _c("i", {
                            staticClass: "mr-1",
                            attrs: { "data-feather": "chevron-left" }
                          }),
                          _vm._v("Back")
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w-50 pl-2" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary btn-block",
                          attrs: { to: { name: "checkout-payment" } }
                        },
                        [
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v("Proceed to payment")
                          ]),
                          _c("span", { staticClass: "d-inline d-sm-none" }, [
                            _vm._v("Next")
                          ]),
                          _c("i", {
                            staticClass: "ml-1",
                            attrs: { "data-feather": "chevron-right" }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("checkout-order-summary", {
                attrs: { "show-promo-code-form": false }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "align-middle" }),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle" }, [_vm._v("Shipping method")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle" }, [_vm._v("Delivery time")]),
        _vm._v(" "),
        _c("th", { staticClass: "align-middle" }, [_vm._v("Shipping fee")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "align-middle" }, [
      _c("span", { staticClass: "text-dark" }, [_vm._v("Default Shipping")]),
      _c("br"),
      _c("span", { staticClass: "text-muted font-size-sm" }, [
        _vm._v("All addresses in United States")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "align-middle" }, [
      _c("span", { staticClass: "text-dark" }, [
        _vm._v("FedEx Express Saver®")
      ]),
      _c("br"),
      _c("span", { staticClass: "text-muted font-size-sm" }, [
        _vm._v("All addresses in United States")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "align-middle" }, [
      _c("span", { staticClass: "text-dark" }, [_vm._v("FedEx 2 Day")]),
      _c("br"),
      _c("span", { staticClass: "text-muted font-size-sm" }, [
        _vm._v("All addresses in United States")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "align-middle" }, [
      _c("span", { staticClass: "text-dark" }, [_vm._v("USPS International")]),
      _c("br"),
      _c("span", { staticClass: "text-muted font-size-sm" }, [
        _vm._v("All addresses outside United States")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }