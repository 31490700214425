<template>
    <div class="comparison-subscription">
        <a class="comparison-subscription-title" href="#">{{ planName}}</a>
        <p>Annual Subscription</p>
        <button v-if="isPlanInCart" class="btn btn-pill btn-success btn-sm" disabled>Selected</button>
        <button v-else-if="! isAuthenticated" class="btn btn-pill btn-outline-primary btn-sm" type="button" @click.stop="addPlanToCart()" data-toggle="toast" data-target="#cart-toast">Select this Plan</button>
        <button v-else-if="isHigherPlan" class="btn btn-pill btn-outline-primary btn-sm" type="button" @click.stop="addPlanToCart()" data-toggle="toast" data-target="#cart-toast">Upgrade</button>
        <h4 v-else-if="isCurrentPlan"><span class="badge badge-secondary">Current Plan</span></h4>
        <button v-else-if="! hasActivePlan" class="btn btn-pill btn-outline-primary btn-sm" type="button" @click.stop="addPlanToCart()" data-toggle="toast" data-target="#cart-toast">Select this Plan</button>
    </div>
</template>

<script>
    export default {
        name: "PlanComparisonHeader",
        props: ['productKey'],
        data() {
            return {
                plan: this.$store.getters.getPlanByProductKey(this.productKey),
            };
        },
        computed: {
            planName() {
                switch (this.productKey) {
                    case ("PLAN_SILVER"): return "Silver Plan";
                    case ("PLAN_GOLD"): return "Gold Plan";
                    case ("PLAN_DIAMOND"): return "Diamond Plan";
                }
            },
            isAuthenticated() {
                return this.$auth.isAuthenticated();
            },
            hasActivePlan() {
                return this.$store.getters.hasActivePlan;
            },
            currentPlan() {
                return this.$store.getters.getCurrentPlan;
            },
            planInCart() {
                return this.$store.getters.getPlanInCart;
            },
            isHigherPlan() {
                return (this.isAuthenticated) && (this.hasActivePlan) && (this.plan.price > this.currentPlan.price);
            },
            isCurrentPlan() {
                return (this.isAuthenticated) && (this.hasActivePlan) && (this.plan.productKey === this.currentPlan.productKey);
            },
            isPlanInCart() {
                return ((this.planInCart) && (this.plan.productKey === this.planInCart.productKey));
            }
        },
        methods: {
            async addPlanToCart() {
                if (this.hasActivePlan) {
                   await this.getUpgradePriceForPlan(this.plan.id)
                }
                this.$store.dispatch('addProductToCart', this.plan.id);
            },
            async getUpgradePriceForPlan(planId) {
                const accessToken = await this.$auth.getAccessToken();
                const url = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/plans/' + planId + '/');
                return axios.get(url, {
                    crossDomain: true,
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                })
                .then(response => {
                    this.$store.dispatch('updatePlanInProducts', {id: response.data.id, price: response.data.price});
                });
            }
        }
    }
</script>

<style scoped>
    .comparison-subscription {
        position: relative;
        font-size: 1.25em;
        padding: 0.875rem 0.75rem 1.125rem;
        border: 1px solid #e7e7e7;
        background-color: #fff;
        text-align: center;
        min-height: 164px;
    }
    .comparison-subscription-title {
        font-size: 2rem;
        color: #0f0f0f;
        display: block;
        width: 100%;
        text-decoration: none;
    }
</style>
