<template>
    <div>
        <bread-crumb :pageTitle="'Checkout'"></bread-crumb>
        <div class="container pb-5 mb-sm-4 mt-n2 mt-md-n3">
            <!-- Steps (wizard)-->
            <checkout-steps :stepNumber="4"></checkout-steps>
            <div class="row pt-4 mt-2">
                <!-- Content-->
                <div class="col-xl-9 col-md-8">
                    <h2 class="h6 px-4 py-3 bg-secondary mb-4">Enter your payment</h2>
                    <form method="post" id="payment-form" class="needs-validation" novalidate>
                        <div class="list-group mb-4">
                            <div v-for="(paymentSource, index) in paymentSources" class="list-group-item">
                                <div class="card-element card-number custom-control custom-radio">
                                    <input class="custom-control-input" :id="paymentSource.id" type="radio" name="payment-method" :value="paymentSource.id" v-model="sourceId">
                                    <label class="custom-control-label" v-bind:for="paymentSource.id"></label>
                                    <i class="card-brand pf" v-bind:class="brandClass(paymentSource.card.brand)"></i><span>&nbsp;&nbsp;**** **** **** {{ paymentSource.card.last4 }}&nbsp;&nbsp;</span>
                                    <span v-if="(index === 0)" class="badge badge-pill badge-primary">Default</span>
                                </div>
                                <div class="card-element">
                                    <span> {{ paymentSource.card.expMonth  }} / {{ paymentSource.card.expYear  }} </span>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row card-element" v-bind:class="{'custom-control custom-radio': (paymentSources.length > 0)}">
                                    <div class="col-sm-12">
                                        <input v-if="paymentSources.length > 0" class="custom-control-input" id="new-card" type="radio" name="payment-method" value="0" v-model="sourceId">
                                        <label v-if="paymentSources.length > 0" class="custom-control-label mr-1" for="new-card"></label>
                                        <span>We accept the following credit cards:&nbsp;&nbsp;<img class="d-inline-block align-middle" src="images/cards.png" style="width: 187px;" alt="Cerdit Cards"></span>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-6">
                                        <span class="brand float-right"><i class="pf pf-credit-card" id="brand-icon"></i></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <div id="stripe-card-element" class="stripe-element">
                                        </div>
                                        <div id="card-number-errors" class="invalid-feedback"></div>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <input class="form-control" type="text" v-model.trim="$v.billingName.$model" name="billing-name" placeholder="Full Name" :class="{ 'is-invalid': $v.billingName.$error }" @focus="resetSourceId()" required>
                                        <div class="invalid-feedback" v-if="!$v.billingName.required">Please enter the billing name</div>
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <div id="stripe-expiry-element" class="stripe-element"/>
                                        <div id="card-expiry-errors" class="invalid-feedback"></div>
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <div id="stripe-cvc-element" class="stripe-element"/>
                                        <div id="card-cvc-errors" class="invalid-feedback"></div>
                                    </div>
                                    <div class="col-sm-6">
                                    </div>
                                    <div class="col-sm-12">
                                        <div id="card-errors" role="alert"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Navigation-->
                        <div class="d-flex pt-2">
                            <div class="w-50 pr-3"><router-link :to="{ name: backStepLink }" class="btn btn-outline-secondary btn-block"><i class="mr-1" data-feather="chevron-left"></i>Back</router-link></div>
                            <div class="w-50 pl-2"><a class="btn btn-primary btn-block" v-bind:class="{ disabled: ! isFormComplete}" href="#" @click.prevent="setPaymentSource()"><span class="d-none d-sm-inline">Review your order</span><span class="d-inline d-sm-none">Next</span><i class="ml-1" data-feather="chevron-right"></i></a></div>
                        </div>
                    </form>
                </div>
                <!-- Sidebar-->
                <checkout-order-summary v-bind:show-promo-code-form="true"></checkout-order-summary>
            </div>
        </div>
    </div>
</template>

<script>
    import router from './../router';
    import BreadCrumb from "../components/BreadCrumb";
    import CheckoutSteps from "../components/CheckoutSteps";
    import CheckoutOrderSummary from "../components/CheckoutOrderSummary";
    import { required } from 'vuelidate/lib/validators';

    var stripe;
    var elements;
    var cardNumberElement;

    var cardBrandToPfClass = {
        'visa': 'pf-visa',
        'mastercard': 'pf-mastercard-alt',
        'amex': 'pf-american-express',
        'discover': 'pf-discover',
        'diners': 'pf-diners-alt',
        'jcb': 'pf-jcb',
        'unionpay': 'pf-unionpay',
        'unknown': 'pf-credit-card',
    };

    export default {
        name: "CheckoutPaymentPage",
        components: {BreadCrumb, CheckoutSteps, CheckoutOrderSummary},
        data: function() {
            return {
                paymentSources: [],
                sourceId: 0,
                billingName: this.$store.state.billingAddress.firstName + ' ' + this.$store.state.billingAddress.lastName,
                isCardComplete: false,
                isExpiryComplete: false,
                isCVCComplete: false,
                requiresShipping: this.$store.getters.getRequiresShipping,
            }
        },
        validations: {
            billingName: { required },
        },
        computed: {
            isFormComplete() {
                return (this.sourceId != 0) || (this.isCardComplete && this.isExpiryComplete && this.isCVCComplete && this.billingName);
            },
            backStepLink() {
                return (this.requiresShipping) ? 'checkout-shipping' : 'checkout-details';
            }
        },
        async created() {
            const accessToken = await this.$auth.getAccessToken();
            const url = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/payment-sources/');
            axios.get(url,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        this.paymentSources = response.data;
                        if (this.paymentSources.length > 0) {
                            this.sourceId = this.paymentSources[0].id;
                        }
                    });
        },
        mounted() {
            stripe = Stripe(process.env.MIX_STRIPE_PUBLISHABLE_KEY);
            elements = stripe.elements({
                fonts: [{
                    cssSrc: 'https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800'
                }]
            });

            var style = {
                base: {
                    'fontFamily': 'Muli',
                    'fontSize': '16px',
                    'color': '#404040',
                    'fontWeight': '400',
                    'fontSmoothing': 'antialiased',
                    'lineHeight': 1.5,
                },
                invalid: {
                    color: '#f44336',
                    iconColor: '#cc0000'
                }
            };

            cardNumberElement = elements.create('cardNumber', {style: style});
            cardNumberElement.mount('#stripe-card-element');

            var that = this;
            cardNumberElement.on('change', function(event) {
                if (event.brand) {
                    that.setBrandIcon(event.brand);
                }
                var displayCardError = document.getElementById('card-number-errors');
                displayCardError.textContent = (event.error) ? event.error.message : '';
                displayCardError.style.display = (event.error) ? "block" : "none";
                that.isCardComplete = event.complete;
            });

            cardNumberElement.on('focus', function(event) {
                that.sourceId = 0;
            });

            var cardExpiryElement = elements.create('cardExpiry', {style: style});
            cardExpiryElement.mount('#stripe-expiry-element');

            cardExpiryElement.on('change', function(event) {
                var displayCardExpiryError = document.getElementById('card-expiry-errors');
                displayCardExpiryError.textContent = (event.error) ? event.error.message : '';
                displayCardExpiryError.style.display = (event.error) ? "block" : "none";
                that.isExpiryComplete = event.complete;
            });

            cardExpiryElement.on('focus', function(event) {
                that.sourceId = 0;
            });

            var cardCvcElement = elements.create('cardCvc', {style: style});
            cardCvcElement.mount('#stripe-cvc-element');

            cardCvcElement.on('change', function(event) {
                var displayCardCVCError = document.getElementById('card-cvc-errors');
                displayCardCVCError.textContent = (event.error) ? event.error.message : '';
                displayCardCVCError.style.display = (event.error) ? "block" : "none";
                that.isCVCComplete = event.complete;
            });

            cardCvcElement.on('focus', function(event) {
                that.sourceId = 0;
            });
        },
        methods: {
            brandClass(value) {
                if (value in cardBrandToPfClass) {
                    return cardBrandToPfClass[brand];
                }
            },
            setBrandIcon: function(brand) {
                var brandIconElement = document.getElementById('brand-icon');
                var pfClass = 'pf-credit-card';
                if (brand in cardBrandToPfClass) {
                    pfClass = cardBrandToPfClass[brand];
                }
                for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
                    brandIconElement.classList.remove(brandIconElement.classList[i]);
                }
                brandIconElement.classList.add('pf');
                brandIconElement.classList.add(pfClass);
            },
            resetSourceId() {
                this.sourceId = 0;
            },
            setPaymentSource: function() {
                if (this.sourceId == 0) {
                    this.createStripeSource();
                }
                else {
                    var last4;
                    var that = this;
                    this.paymentSources.forEach(function (item) {
                        if (item.id === that.sourceId) {
                            last4 = item.card.last4;
                        }
                    });
                    that.$store.dispatch('setStripeSourceDetails', {
                        token: this.sourceId,
                        last4: last4
                    });
                    router.push({name: 'checkout-review'});
                }
            },
            createStripeSource: function() {
                var that = this;
                stripe.createSource(cardNumberElement).then(function(result) {
                    if (result.error) {
                        var errorElement = document.getElementById('card-errors');
                        errorElement.textContent = result.error.message;
                    } else {
                        //console.log(result.source);
                        that.$store.dispatch('setStripeSourceDetails', {
                            token: result.source.id,
                            last4: result.source.card.last4
                        });
                        router.push({name: 'checkout-review'});
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .stripe-element {
        display: block;
        width: 100%;
        height: calc(1.5em + 1rem + 2px);
        padding: 0.5rem 1rem;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e1e1e1;
        border-radius: 0;
        flex: 1;
        transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }

    .stripe-element:focus,
    .stripe-element.StripeElement--focus {
        color: #404040;
        background-color: #fff;
        border-color: transparent;
        outline: 0;
        box-shadow: 0 0 0 0.0625rem rgba(92, 119, 252, 0.35);
    }

    .StripeElement--invalid {
        border-color: #f44336;
    }

    span.brand {
        width: 30px;
    }

    #card-errors {
        padding: 4px 0;
        color: #f44336;
    }

    .card-element {
        display: inline-block;
    }

    .card-number {
        min-width: 300px;
    }

</style>
