var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass: "page-title-wrapper",
          attrs: { "aria-label": "Page title" }
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("h1", { staticClass: "page-title" }, [_vm._v("Privacy Policy")]),
            _c("span", { staticClass: "d-block mt-2 text-muted" }),
            _vm._v(" "),
            _c("hr", { staticClass: "mt-4" })
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container pb-5 mb-1" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-lg-9" }, [
            _c("h3", { staticClass: "h4 pb-4" }, [
              _vm._v("Effective Date: January 1st, 2020")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "GemEx Systems, Inc and DiamondEye, respects your concerns about privacy and values our relationship with you. This Privacy Notice applies to the online practices of www.diamondeye.app Web sites. The Notice describes the types of information we collect, how we may use that information and with whom we may share it. Our Privacy Notice also describes the measures we take to protect the security of the information. We also tell you how you can reach us to update your contact information, remove your name from our mailing lists or get answers to questions you may have about our privacy practices at these sites."
              )
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "h5 pt-4 pb-2 mt-2" }, [
              _vm._v("Information We Collect and How We Use It")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Your Personal Information")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You may choose to provide personal information (such as your name, address, telephone number and e-mail address) online via our Web sites. Here are the ways in which you may provide the information and the types of information you may submit. We also tell you how we may use the information."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Information Request")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "If you request information about our products or access to our proprietary Web pages and tools, we may ask you to provide details such as your name, e-mail address, telephone number, if you have a DiamondEye product and its ID number, your jewelry interests, etc. You also may choose to provide additional comments and requests. We, or an authorized dealer in your area, will use your details to send you information if so requested and to communicate with you in the future about our products and services."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Newsletters and Other")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "From time to time, our site may offer online newsletters and other communications about our products, services and promotions. To sign up, we will ask you for information such as your name, postal address and e-mail address. We, or an authorized dealer in your area, use the information to send you the communications you request and to communicate with you about our products and services."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Contacting Us")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "If you e-mail us or the companies named in the Web site pages which are affiliated with DiamondEye, we will receive your e-mail address and any other information you choose to include in your e-mail to respond to your inquiry. If you choose to communicate with us through the “Contact Us Here” link on our Web sites, we may ask you for information such as your name, e-mail address and telephone number so we can respond to your questions and comments. We, or an authorized dealer in your area, also may use the information you provide to communicate with you about our products and services."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Customer Service Calls")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You may choose to contact our Customer Service, to order parts, or contact us with questions on our products. Depending on your request, we may ask for information such as your name, postal address, e-mail address, telephone number, product ID or serial number. We use this information to provide you with the service and assistance you requested. We also may use the information to communicate with you about our products and services."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Feedback, Surveys, or Contests")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "From time to time, we may ask you to participate in online surveys, solicitations, or contests. Additional information requested may include contact information (such as e-mail address) and demographic information (such as number of family members and income by ranges). Participation in surveys, solicitations, or contests is completely voluntary. Therefore, you have a choice as to whether to disclose any information. We, or an authorized dealer in your area, will use the information provided to notify contest winners and award prizes, to monitor or improve the use of one or more sites or advertising, and to provide aggregated information for marketing analyses. This information will be used to analyze the general public’s present and future needs related to our products, and to communicate with you about our products and services, and to send you further notices, promotions, solicitations, or brochures, and other marketing materials regarding our Web site, our products, and the services of our businesses and the businesses of our affiliates, business partners, or authorized dealers, unless we have been notified that you do not wish to be contacted for marketing concerns by contacting us as indicated in the “How to Contact Us” section of this Notice. Solicitations may take the form of mailings via U.S. mail or other couriers, or e-mail (if provided)."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Locate a Retailer")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You may want to locate a retailer near you by completing a “find a retailer” request form. We ask you for information such as your first and last name, e-mail address, full postal address and telephone number. We use this information to locate a dealer near you, to evaluate your need and to give you the best and most relevant service. We, or an authorized dealer in your area, also may use the information to communicate with you about our products and services."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Ask for a Quote")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Before purchasing one of our products, you may want to ask for a quote. We ask for certain information such as name, e-mail address, full postal address, telephone number, etc. We will use this information to create the quote and to send it to you. We, or the authorized dealer in your area, may also use this information to communicate with you in the future about our products and services."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Owner’s Programs or Rewards Programs")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "If you would like to participate in our DiamondEye programs, we may ask for information such as name and e-mail address so that we can enroll you in these programs and communicate with you."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Online Sales")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Our branded merchandise is offered for sale by us through our Web site, or through an authorized dealer’s Web site which is linked to our site. If your purchase will be conducted other than on our Web site and you should review the privacy notice and terms of use that apply to that Web site."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Employment Opportunities")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We may include a link to submit your resume and other information, such as your name, contact information, and job history. We use the information you submit to evaluate your interest in employment and to contact you regarding possible employment."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Other Features")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We offer consumers various features on our Web sites for their information and enjoyment, which we may change from time to time. We may ask you to submit certain personal information so we, or an authorized dealer in your area, may use this information to provide you with these features or to communicate with you in the future about our products and services."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Other Uses of Information")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We may use the personal information you provide for internal purposes. These purposes include administration, data analytics and compliance with our legal obligations, policies and procedures."
              )
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "h5 pt-4 pb-2 mt-2" }, [
              _vm._v("Information We Collect by Automated Means")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We collect certain information by automated means when you visit our Web sites, such as how many users visited our sites and the pages accessed. By collecting this information, we learn how to best tailor our Web sites to our visitors. We collect this information through various means such as “cookies” and “Web tags” We may collect IP addresses, as explained below."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [_vm._v("Cookies")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Like many companies, we use “cookies” on our Web sites. Cookies are bits of text that are placed on your computer’s hard drive when you visit certain Web sites. We use cookies to tell us, for example, whether you have visited us before or if you are a new visitor and to help us identify site features in which you may have the greatest interest. Cookies may enhance your online experience by saving your preferences while you are visiting a particular site. Most browsers will tell you how to stop accepting new cookies, how to be notified when you receive a new cookie, and how to disable existing cookies. Please note, however, that without cookies you may not be able to take full advantage of all our Web site features."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [_vm._v("Web Tags")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Certain pages on our Web sites contain “Web tags”  These Web tags allow third parties to obtain information such as the IP address of the computer that downloaded the page on which the tags appears, the URL of the page on which the tag appears, the time the page containing the tag was viewed, the type of browser used to view the page, and the information in cookies./p>\n                "
              )
            ]),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("IP Addresses")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "An IP address is a unique identifier that certain electronic devices use to identify and communicate with each other on the Internet. When you visit our Web sites, we may view the IP address of the device you use to connect to the Internet. We use this information to determine the general physical location of the device and understand from what regions of the world our Web site visitors come. We also may use this information to enhance our Web sites."
              )
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "h5 pt-4 pb-2 mt-2" }, [
              _vm._v("Information We Share")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We do not sell or otherwise disclose information about our Web site visitors, except as described here. We may share the information you provide among our subsidiaries and affiliates, and with DiamondEye business partners, such as our joint marketing partners and retailers and distributors of our products. These service providers are not authorized by us to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements. In addition, we may disclose information about you (i) if we are required to do so by law or legal process, (ii) to law enforcement authorities or other government officials, (iii) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity or (iv) if this is necessary to protect the vital interests of the a person."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "If you are a California resident, you may ask us to refrain from sharing your information with third parties and among our affiliates for their marketing purposes, if the affiliates are separate legal entities. Please tell us your preference by contacting us as indicated in the “How to Contact Us” section of this Notice."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We reserve the right to transfer any information we have about you in the event we sell or transfer all or a portion of our business or assets. Should such a sale or transfer occur, we will use reasonable efforts to direct the transferee to use information you have provided through our Web sites in a manner that is consistent with this Privacy Notice."
              )
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "h5 pt-4 pb-2 mt-2" }, [
              _vm._v("Links to Other Sites")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Our Web sites may provide links to other Web sites for your convenience and information. These Web sites may operate independently from this site. Linked sites may have their own privacy notices or policies, which we strongly suggest you review if you visit any linked Web sites you visit are not part of this site, we are not responsible for their content, any use of the sites, or the privacy practices of the sites.. To the extent any linked Web sites"
              )
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "h5 pt-4 pb-2 mt-2" }, [
              _vm._v("How We Protect Personal Information")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We maintain administrative, technical and physical safeguards to protect against loss, misuse or unauthorized access, disclosure, alteration or destruction of the information you provide on our Web sites."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Information We Transfer")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Web sites to which this Privacy Notice applies are United States-based Web sites and are subject to United States law. We may transfer the personal information we collect on this site to other countries where we do business, which may not have the same data protection laws as the country in which you reside. When we transfer your information to other countries, we will protect that information as described here, even though a given country may not have the same privacy and data protection laws as the country in which you reside."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Access and Correction")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We provide Web site visitors with reasonable access to personal information they provide through our sites and the ability to review and correct it, as applicable. To help protect your privacy and security, we will take reasonable steps to verify your identity, such as requiring a password and user ID, before granting access to your information."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Updates to Our Privacy Notice")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "This Privacy Notice may be updated periodically and without prior notice to you to reflect changes in our online information practices. We will post a prominent notice on this site to notify you of any significant changes to our Privacy Notice and indicate at the top of the notice when it was most recently updated."
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("Your Choices – How To Contact Us")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "If you would like to update your contact information or preferences, have your information removed from our mailing lists, or no longer receive marketing e-mails resulting from information collected via product registration cards or other sources, please contact:"
              )
            ]),
            _vm._v(" "),
            _c("address", [
              _vm._v("\n                    Paul Town"),
              _c("br"),
              _vm._v("\n                    Marketing Division"),
              _c("br"),
              _vm._v("\n                    6040 W. Executive Drive"),
              _c("br"),
              _vm._v("\n                    Suite A"),
              _c("br"),
              _vm._v("\n                    Mequon, WI 53092"),
              _c("br"),
              _vm._v("\n                    Phone: 262-242-1111"),
              _c("br"),
              _vm._v("\n                    Fax: 262-242-1191"),
              _c("br"),
              _vm._v("\n                    Email: info@gemex.com"),
              _c("br")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }