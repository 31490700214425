var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", { attrs: { to: "/diamondeye-viewer" } }, [
    _c(
      "button",
      {
        staticClass: "btn btn-primary btn-lg rounded",
        attrs: { type: "button" }
      },
      [
        _vm._v(_vm._s(_vm.actionText)),
        _c("i", {
          staticClass: "ml-2",
          attrs: { "data-feather": "arrow-right" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }