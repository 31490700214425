<template>
<div>
    <app-header></app-header>
    <v-dialog/>
    <router-view></router-view>
    <app-footer></app-footer>
</div>
</template>

<script>
    import AppHeader from "./AppHeader";
    import feather from 'feather-icons';
    import AppFooter from "./AppFooter";

    export default {
        name: "App",
        components: {
            AppFooter,
            AppHeader
        },
        data() {
            return {
                loading: true
            };
        },
        async created() {
            try {
                await this.$auth.renewTokens();

            } catch {
                // Supress the 'not logged in' error as we can illegitimately get that
                // when processing the callback url
            }
        },
        created() {
            this.$store.dispatch('fetchProducts').then (
                () => { this.loading = false; }
            );
        },
        mounted() {
            feather.replace();
        }
    }
</script>

<style scoped>

</style>
