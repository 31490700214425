var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container pb-5 mb-1" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "div",
        { staticClass: "col-lg-9" },
        [
          _vm._m(0),
          _vm._v(" "),
          !this.isValid && !this.showMasterImage
            ? [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-danger",
                    attrs: { role: "alert" }
                  },
                  [
                    _vm._v(
                      "\n                    Not recognized as a valid Diamond ID Number\n                "
                    )
                  ]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          !this.showMasterImage
            ? _c(
                "form",
                {
                  staticClass: "ml-3 needs-validation",
                  attrs: { novalidate: "" }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "company-id" } }, [
                          _vm._v("Supplier Code")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.companyIdInput.$model,
                              expression: "$v.companyIdInput.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.companyIdInput.$error },
                          attrs: { type: "text", id: "company-id" },
                          domProps: { value: _vm.$v.companyIdInput.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.companyIdInput,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        !_vm.$v.companyIdInput.required
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("Please enter a supplier code")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "diamond-id" } }, [
                          _vm._v("Diamond Identification Number")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.diamondIdInput.$model,
                              expression: "$v.diamondIdInput.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.diamondIdInput.$error },
                          attrs: { type: "text", id: "diamond-id" },
                          domProps: { value: _vm.$v.diamondIdInput.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.diamondIdInput,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        !_vm.$v.diamondIdInput.required
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v("Please enter a Diamond ID Number")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-12 text-center" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary btn-lg",
                            class: { disabled: _vm.isFormCompleted },
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.loadDiamond()
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "d-none d-sm-inline" }, [
                              _vm._v("Load into my DiamondEye App")
                            ]),
                            _c("span", { staticClass: "d-inline d-sm-none" }, [
                              _vm._v("Load into my DiamondEye App")
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          this.showMasterImage
            ? _c("div", { staticClass: "ml-3" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("img", {
                        attrs: {
                          src: this.masterImageURL,
                          alt: "Scan Master Image"
                        }
                      })
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2)
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mt-5 offset-sm-4 col-sm-4" }, [
        _c("img", {
          staticClass: "mb-grid-gutter",
          attrs: { src: "/images/diamondeye-logo.png", alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card mt-4" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h3", { staticClass: "card-title text-center" }, [
          _vm._v("Download the App")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 text-center" }, [
            _c(
              "a",
              {
                staticClass: "market-btn apple-btn market-btn-light bg-dark",
                attrs: {
                  href:
                    "https://apps.apple.com/id/app/diamondeyepro/id6479329165",
                  role: "button"
                }
              },
              [
                _c("span", { staticClass: "market-button-subtitle" }, [
                  _vm._v("Download on the")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "market-button-title" }, [
                  _vm._v("App Store")
                ])
              ]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-5 pb-3" }, [
      _c("div", { staticClass: "text-center col-md-4 offset-md-4" }, [
        _c(
          "a",
          { staticClass: "btn btn-lg btn-primary", attrs: { href: "/" } },
          [_vm._v("Learn more about DiamondEye")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }