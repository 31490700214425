var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-xl-3 col-md-4 pt-4 mt-3 pt-md-0 mt-md-0" },
    [
      _c("h2", { staticClass: "h6 px-4 py-3 bg-secondary text-center" }, [
        _vm._v("Order summary")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "font-size-sm border-bottom pt-2 pb-3" },
        [
          _c("div", { staticClass: "d-flex justify-content-between mb-2" }, [
            _c("span", [_vm._v("Subtotal:")]),
            _c("span", [_vm._v(_vm._s(_vm.totalCartAmount))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-between mb-2" }, [
            _c("span", [_vm._v("Shipping:")]),
            _c("span", [_vm._v(_vm._s(_vm.shippingAmount))])
          ]),
          _vm._v(" "),
          _vm.discountCodeApplied
            ? [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between mb-2" },
                  [
                    _c("span", [
                      _vm._v(
                        "Discount (" + _vm._s(_vm.discountCodeApplied) + "):"
                      )
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.discountAmount))])
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h3 font-weight-semibold text-center py-3" }, [
        _vm._v(_vm._s(_vm.totalOrderAmount))
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.showPromoCodeForm
        ? _c("div", { staticClass: "pt-4" }, [
            _c(
              "div",
              { staticClass: "accordion", attrs: { id: "cart-accordion" } },
              [
                _c("div", { staticClass: "card" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "collapse show",
                      attrs: {
                        id: "promocode",
                        "data-parent": "#cart-accordion"
                      }
                    },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "form",
                          {
                            staticClass: "needs-validation",
                            attrs: { novalidate: "" }
                          },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.promoCode,
                                    expression: "promoCode",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.showInvalidPromoCodeMessage
                                },
                                attrs: {
                                  type: "text",
                                  name: "promo-code",
                                  placeholder: "Promo code"
                                },
                                domProps: { value: _vm.promoCode },
                                on: {
                                  keyup: _vm.clearPromoCodeMessage,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.promoCode = $event.target.value.trim()
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.showInvalidPromoCodeMessage
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "Please provide a valid promo code"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-outline-primary btn-block",
                                class: { disabled: _vm.isMissingPromoCode },
                                attrs: { disabled: _vm.isMissingPromoCode },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.applyPromoCode()
                                  }
                                }
                              },
                              [_vm._v("Apply promo code")]
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" })
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isProcessing
        ? _c("BlockUI", { attrs: { message: _vm.processMessage } }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                staticStyle: { width: "3rem", height: "3rem" },
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", { staticClass: "accordion-heading font-weight-semibold" }, [
        _c(
          "a",
          {
            attrs: {
              href: "#promocode",
              role: "button",
              "data-toggle": "collapse",
              "aria-expanded": "true",
              "aria-controls": "promocode"
            }
          },
          [
            _vm._v("Apply promo code"),
            _c("span", { staticClass: "accordion-indicator" }, [
              _c("i", { attrs: { "data-feather": "chevron-up" } })
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }