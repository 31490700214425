var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container pt-lg-4 pb-5 mb-sm-3" },
    [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-wrap justify-content-between pb-4" },
          [
            _c("div", { staticClass: "pt-3 mr-3" }, [
              _c("h3", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.userName))]),
              _c("span", { staticClass: "font-size-sm text-warning" }, [
                _vm._v(_vm._s(_vm.email))
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("ul", { staticClass: "list-unstyled border p-3 mb-4" }, [
          _c("h5", [_vm._v("Your Plan")]),
          _vm._v(" "),
          _vm.currentPlan.id
            ? _c("div", [
                _c("li", { staticClass: "pb-1" }, [
                  _c("span", { staticClass: "opacity-80" }, [
                    _vm._v("Current Subscription Plan: ")
                  ]),
                  _c("span", { staticClass: "font-weight-semibold ml-1" }, [
                    _vm._v(_vm._s(_vm.currentPlan.name))
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "pb-1" }, [
                  _c("span", { staticClass: "opacity-80" }, [
                    _vm._v("Your Plan will Expire On: ")
                  ]),
                  _c("span", { staticClass: "font-weight-semibold ml-1" }, [
                    _vm._v(_vm._s(_vm.currentPlanExpirationDate))
                  ])
                ]),
                _vm._v(" "),
                _vm.numOfVerificationsRemaining > 0
                  ? _c("li", { staticClass: "pb-1" }, [
                      _c("span", { staticClass: "opacity-80" }, [
                        _vm._v("You have")
                      ]),
                      _c("span", { staticClass: "font-weight-semibold ml-1" }, [
                        _vm._v(_vm._s(_vm.numOfVerificationsRemaining))
                      ]),
                      _vm._v(" Diamond Verification Available")
                    ])
                  : _c("li", [
                      _c("span", { staticClass: "opacity-80" }, [
                        _vm._v(
                          "You have no more diamond verification available"
                        )
                      ])
                    ]),
                _vm._v(" "),
                _vm.numOfProngChecksRemaining > 0
                  ? _c("li", { staticClass: "pb-1" }, [
                      _c("span", { staticClass: "opacity-80" }, [
                        _vm._v("You have")
                      ]),
                      _c("span", { staticClass: "font-weight-semibold ml-1" }, [
                        _vm._v(_vm._s(_vm.numOfProngChecksRemaining))
                      ]),
                      _vm._v(" Diamond Prong Checks Available")
                    ])
                  : _c("li", [
                      _c("span", { staticClass: "opacity-80" }, [
                        _vm._v(
                          "You have no more diamond prong checks available"
                        )
                      ])
                    ]),
                _vm._v(" "),
                _vm.numOfRegistrationRemaining > 0
                  ? _c("li", { staticClass: "pb-1" }, [
                      _c("span", { staticClass: "opacity-80" }, [
                        _vm._v("You have")
                      ]),
                      _c("span", { staticClass: "font-weight-semibold ml-1" }, [
                        _vm._v(_vm._s(_vm.numOfRegistrationRemaining))
                      ]),
                      _vm._v(" Diamond Registration Available")
                    ])
                  : _c("li", [
                      _c("span", { staticClass: "opacity-80" }, [
                        _vm._v(
                          "You have no more diamond registration available"
                        )
                      ])
                    ]),
                _vm._v(" "),
                _c("h5", { staticClass: "mt-4" }, [
                  _vm._v("Purchase Additional Services")
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mb-2 mr-2",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addProductToCart("SINGLE_VERIFICATION")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Purchase Verification " +
                        _vm._s(
                          _vm.formatAsCurrency(_vm.additionalVerificationPrice)
                        )
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mb-2 mr-2",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addProductToCart("SINGLE_PRONG_CHECK")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Purchase Prong Check " +
                        _vm._s(
                          _vm.formatAsCurrency(_vm.additionalProngCheckPrice)
                        )
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mb-2 mr-2",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addProductToCart("SINGLE_REGISTRATION")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Purchase Registration " +
                        _vm._s(
                          _vm.formatAsCurrency(_vm.additionalRegistrationPrice)
                        )
                    )
                  ]
                )
              ])
            : _c("div", [_vm._m(1)])
        ]),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "nav nav-tabs d-none d-sm-flex",
            attrs: { role: "tablist" }
          },
          [
            _vm._m(2),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    href: "#my-diamonds",
                    "data-toggle": "tab",
                    role: "tab"
                  }
                },
                [
                  _c("i", { attrs: { "data-feather": "heart" } }),
                  _vm._v(" My Diamonds"),
                  _c(
                    "span",
                    {
                      staticClass:
                        "badge badge-pill badge-secondary bg-0 border ml-2"
                    },
                    [
                      _c("span", { staticClass: "text-primary" }, [
                        _vm._v(_vm._s(_vm.myDiamonds.length))
                      ])
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    href: "#my-payment-methods",
                    "data-toggle": "tab",
                    role: "tab"
                  }
                },
                [
                  _c("i", { attrs: { "data-feather": "credit-card" } }),
                  _vm._v(" Payment Methods"),
                  _c(
                    "span",
                    {
                      staticClass:
                        "badge badge-pill badge-secondary bg-0 border ml-2"
                    },
                    [
                      _c("span", { staticClass: "text-primary" }, [
                        _vm._v(_vm._s(_vm.myPaymentMethods.length))
                      ])
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    href: "#my-orders",
                    "data-toggle": "tab",
                    role: "tab"
                  }
                },
                [
                  _c("i", { attrs: { "data-feather": "shopping-bag" } }),
                  _vm._v(" My Orders"),
                  _c(
                    "span",
                    {
                      staticClass:
                        "badge badge-pill badge-secondary bg-0 border ml-2"
                    },
                    [
                      _c("span", { staticClass: "text-primary" }, [
                        _vm._v(_vm._s(_vm.myOrders.length))
                      ])
                    ]
                  )
                ]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-sm-none pb-4" }, [
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "collapse", attrs: { id: "account-menu" } },
            [
              _c("div", { staticClass: "list-group" }, [
                _vm._m(5),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: {
                      href: "#my-diamonds",
                      "data-toggle": "tab",
                      role: "tab"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "mr-2",
                      staticStyle: { width: "1rem", height: "1rem" },
                      attrs: { "data-feather": "heart" }
                    }),
                    _vm._v("My Diamonds"),
                    _c(
                      "span",
                      {
                        staticClass:
                          "badge badge-pill badge-secondary bg-0 border ml-2"
                      },
                      [
                        _c("span", { staticClass: "text-primary" }, [
                          _vm._v(_vm._s(_vm.myDiamonds.length))
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(6),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: {
                      href: "#my-payment-methods",
                      "data-toggle": "tab",
                      role: "tab"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "mr-2",
                      staticStyle: { width: "1rem", height: "1rem" },
                      attrs: { "data-feather": "credit-card" }
                    }),
                    _vm._v("Payment Methods"),
                    _c(
                      "span",
                      {
                        staticClass:
                          "badge badge-pill badge-secondary bg-0 border ml-2"
                      },
                      [
                        _c("span", { staticClass: "text-primary" }, [
                          _vm._v(_vm._s(_vm.myPaymentMethods.length))
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: {
                      href: "#my-orders",
                      "data-toggle": "tab",
                      role: "tab"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "mr-2",
                      staticStyle: { width: "1rem", height: "1rem" },
                      attrs: { "data-feather": "shopping-bag" }
                    }),
                    _vm._v("My Orders"),
                    _c(
                      "span",
                      {
                        staticClass:
                          "badge badge-pill badge-secondary bg-0 border ml-2"
                      },
                      [
                        _c("span", { staticClass: "text-primary" }, [
                          _vm._v(_vm._s(_vm.myOrders.length))
                        ])
                      ]
                    )
                  ]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-content" },
          [
            _c(
              "div",
              {
                staticClass: "tab-pane fade show active",
                attrs: { id: "my-profile", role: "tabpanel" }
              },
              [
                _c("form", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "account-fn" } }, [
                        _vm._v("Name")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userName,
                            expression: "userName"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "account-fn",
                          required: "",
                          disabled: ""
                        },
                        domProps: { value: _vm.userName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.userName = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "account-email" } }, [
                        _vm._v("Email Address")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          id: "account-email",
                          required: "",
                          disabled: ""
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane fade",
                attrs: { id: "my-diamonds", role: "tabpanel" }
              },
              [
                _vm.myDiamonds.length === 0
                  ? _c("div", [
                      _vm._v(
                        "\n                    You have no registered diamonds.\n                "
                      )
                    ])
                  : _c(
                      "div",
                      { staticClass: "table-responsive font-size-sm" },
                      [
                        _c("table", { staticClass: "table table-hover mb-0" }, [
                          _vm._m(7),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.myDiamonds, function(myDiamond) {
                              return _c("tr", [
                                _c("td", [_vm._v(_vm._s(myDiamond.stoneId))]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      myDiamond.idScanCustomerData.description
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(
                                        myDiamond.scanCreatedDateTime
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      myDiamond.idScanStatusCode.description
                                    )
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ]
                    )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane fade",
                attrs: { id: "my-retailer", role: "tabpanel" }
              },
              [
                _vm.myRetailer.id === "" && !_vm.isRetailerAssigned
                  ? _c("div", [
                      _vm._v(
                        "\n                    You have no retailer linked to this account.\n                "
                      )
                    ])
                  : _c(
                      "form",
                      { staticClass: "row", attrs: { id: "retailer-form" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.myRetailer.id,
                              expression: "myRetailer.id"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "hidden", id: "retailer-id" },
                          domProps: { value: _vm.myRetailer.id },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.myRetailer,
                                "id",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "retailer-fn" } }, [
                              _vm._v("Retailer Name")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.myRetailer.name,
                                  expression: "myRetailer.name"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "retailer-fn",
                                required: "",
                                disabled: ""
                              },
                              domProps: { value: _vm.myRetailer.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.myRetailer,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "retailer-email" } }, [
                              _vm._v("Retailer Prefix")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.myRetailer.retailerPrefix,
                                  expression: "myRetailer.retailerPrefix"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "retailer-prefix",
                                disabled: ""
                              },
                              domProps: {
                                value: _vm.myRetailer.retailerPrefix
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.myRetailer,
                                    "retailerPrefix",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "retailer-email" } }, [
                              _vm._v("Email Address")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.myRetailer.email,
                                  expression: "myRetailer.email"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "email",
                                id: "retailer-email",
                                disabled: ""
                              },
                              domProps: { value: _vm.myRetailer.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.myRetailer,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "retailer-ph-number" } },
                              [_vm._v("Phone Number")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.myRetailer.phoneNumber,
                                  expression: "myRetailer.phoneNumber"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "retailer-ph-number",
                                disabled: ""
                              },
                              domProps: { value: _vm.myRetailer.phoneNumber },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.myRetailer,
                                    "phoneNumber",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "retailer-website-url" } },
                              [_vm._v("Website URL")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.myRetailer.websiteURL,
                                  expression: "myRetailer.websiteURL"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "retailer-website-url",
                                disabled: ""
                              },
                              domProps: { value: _vm.myRetailer.websiteURL },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.myRetailer,
                                    "websiteURL",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "retailer-video-url" } },
                              [_vm._v("Promotional Video URL")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.myRetailer.videoURL,
                                  expression: "myRetailer.videoURL"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "retailer-video-url",
                                disabled: ""
                              },
                              domProps: { value: _vm.myRetailer.videoURL },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.myRetailer,
                                    "videoURL",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ]
                    )
              ]
            ),
            _vm._v(" "),
            _vm.isRetailerProcessing
              ? _c(
                  "BlockUI",
                  { attrs: { message: _vm.retailerProcessMessage } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "spinner-border text-primary",
                        staticStyle: { width: "3rem", height: "3rem" },
                        attrs: { role: "status" }
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Loading...")
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane fade",
                attrs: { id: "my-orders", role: "tabpanel" }
              },
              [
                _vm.myOrders.length === 0
                  ? _c("div", [
                      _vm._v(
                        "\n                    You have not placed any orders.\n                "
                      )
                    ])
                  : _c(
                      "div",
                      { staticClass: "table-responsive font-size-sm" },
                      [
                        _c("table", { staticClass: "table table-hover mb-0" }, [
                          _vm._m(8),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.myOrders, function(myOrder) {
                              return _c("tr", [
                                _c("td", [_vm._v(_vm._s(myOrder.orderNumber))]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.formatDate(myOrder.orderDate))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatAsCurrency(myOrder.orderAmount)
                                    )
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ]
                    )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane fade",
                attrs: { id: "my-payment-methods", role: "tabpanel" }
              },
              [
                _vm.myPaymentMethods.length === 0
                  ? _c("div", [
                      _vm._v(
                        "\n                    You have no credit/debit cards on file.\n                "
                      )
                    ])
                  : _c(
                      "div",
                      { staticClass: "table-responsive font-size-sm" },
                      [
                        _c("table", { staticClass: "table table-hover mb-0" }, [
                          _vm._m(9),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.myPaymentMethods, function(
                              myPaymentMethod,
                              index
                            ) {
                              return _c("tr", [
                                _c("td", { staticClass: "align-middle" }, [
                                  _c("i", {
                                    staticClass: "card-brand pf",
                                    class: _vm.brandClass(
                                      myPaymentMethod.card.brand
                                    )
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "  **** **** **** " +
                                        _vm._s(myPaymentMethod.card.last4) +
                                        "  "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  index === 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-pill badge-primary"
                                        },
                                        [_vm._v("Default")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "align-middle" }, [
                                  _vm._v(
                                    _vm._s(myPaymentMethod.card.expMonth) +
                                      " / " +
                                      _vm._s(myPaymentMethod.card.expYear)
                                  )
                                ]),
                                _vm._v(" "),
                                index === 0
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "align-middle",
                                        staticStyle: { height: "59px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                 \n                            "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "td",
                                      {
                                        staticClass: "text-center align-middle"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-success mb-2 mr-2",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.setPaymentMethodAsDefault(
                                                  myPaymentMethod.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.checkSvg()
                                                )
                                              }
                                            }),
                                            _vm._v(" Set as Default")
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-danger mb-2 mr-2",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.removePaymentMethod(
                                                  myPaymentMethod.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.circleSvg()
                                                )
                                              }
                                            }),
                                            _vm._v(" Remove")
                                          ]
                                        )
                                      ]
                                    )
                              ])
                            }),
                            0
                          )
                        ])
                      ]
                    ),
                _vm._v(" "),
                _c("hr", { staticClass: "pb-4" }),
                _vm._v(" "),
                _vm._m(10)
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.isProcessing
        ? _c("BlockUI", { attrs: { message: _vm.processMessage } }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                staticStyle: { width: "3rem", height: "3rem" },
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "needs-validation modal fade",
          attrs: {
            method: "post",
            id: "add-payment",
            tabindex: "-1",
            novalidate: ""
          }
        },
        [
          _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(11),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._m(12),
                _vm._v(" "),
                _vm._m(13),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm._m(14),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group col-sm-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.$v.billingName.$model,
                          expression: "$v.billingName.$model",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.billingName.$error },
                      attrs: {
                        type: "text",
                        name: "billing-name",
                        placeholder: "Full Name",
                        required: ""
                      },
                      domProps: { value: _vm.$v.billingName.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.billingName,
                            "$model",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.$v.billingName.required
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v("Please enter the cardholder's name")
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm._m(15),
                  _vm._v(" "),
                  _vm._m(16),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block mt-0",
                        class: { disabled: !_vm.isFormComplete },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.createStripeSource()
                          }
                        }
                      },
                      [_vm._v("Add this card")]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(17)
                ])
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "If you have used up one or more of the DiamondEye services (see your remaining availablity above), you can purchase additional usages via the buttons below. The price per usage is based on your current subscription plan. See the "
      ),
      _c("a", { attrs: { href: "/plan-comparison" } }, [_vm._v("Plans")]),
      _vm._v(" section for more details.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "You are currently not subscribed to any plan. To use the diamond verification or prong check service, you must select a DiamondEye plan."
      ),
      _c("br"),
      _vm._v("\n                See the "),
      _c("a", { attrs: { href: "/plan-comparison" } }, [_vm._v("Plans")]),
      _vm._v(" section for more details.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link active",
          attrs: { href: "#my-profile", "data-toggle": "tab", role: "tab" }
        },
        [_c("i", { attrs: { "data-feather": "user" } }), _vm._v(" My Profile")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: { href: "#my-retailer", "data-toggle": "tab", role: "tab" }
        },
        [
          _c("i", { attrs: { "data-feather": "folder" } }),
          _vm._v(" My Retailer")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-primary btn-block mb-2",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#account-menu"
        }
      },
      [
        _c("i", { staticClass: "mr-2", attrs: { "data-feather": "menu" } }),
        _vm._v("Menu")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "list-group-item list-group-item-action active",
        attrs: { href: "#my-profile", "data-toggle": "tab", role: "tab" }
      },
      [
        _c("i", {
          staticClass: "mr-2",
          staticStyle: { width: "1rem", height: "1rem" },
          attrs: { "data-feather": "user" }
        }),
        _vm._v("My Profile")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "list-group-item list-group-item-action",
        attrs: { href: "#my-retailer", "data-toggle": "tab", role: "tab" }
      },
      [
        _c("i", {
          staticClass: "mr-2",
          staticStyle: { width: "1rem", height: "1rem" },
          attrs: { "data-feather": "folder" }
        }),
        _vm._v("My Retailer")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Diamond Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Registered")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Order #")]),
        _vm._v(" "),
        _c("th", [_vm._v("Order Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Order Amount")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Credit / Debit Cards")]),
        _vm._v(" "),
        _c("th", [_vm._v("Expires on")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-sm-left" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { href: "#add-payment", "data-toggle": "modal" }
        },
        [_vm._v("Add payment method")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Add a payment method")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("We accept following credit cards:  "),
      _c("img", {
        staticClass: "d-inline-block align-middle",
        staticStyle: { width: "187px" },
        attrs: { src: "images/cards.png", alt: "Cerdit Cards" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("span", { staticClass: "brand float-right" }, [
          _c("i", {
            staticClass: "pf pf-credit-card",
            attrs: { id: "brand-icon" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group col-sm-6" }, [
      _c("div", {
        staticClass: "stripe-element",
        attrs: { id: "stripe-card-element" }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "invalid-feedback",
        attrs: { id: "card-number-errors" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group col-sm-3" }, [
      _c("div", {
        staticClass: "stripe-element",
        attrs: { id: "stripe-expiry-element" }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "invalid-feedback",
        attrs: { id: "card-expiry-errors" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group col-sm-3" }, [
      _c("div", {
        staticClass: "stripe-element",
        attrs: { id: "stripe-cvc-element" }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "invalid-feedback",
        attrs: { id: "card-cvc-errors" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { attrs: { id: "card-errors", role: "alert" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }