<template>
    <div>
        <p>About Us!</p>
        <form action="/api/testStripe">
            <button type="submit">Test Stripe</button>
        </form>
    </div>
</template>

<script>
    export default {
        name: "AboutPage",

    }
</script>

<style scoped>

</style>
