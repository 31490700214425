var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "navbar navbar-expand-lg navbar-light fixed-top bg-light" },
    [
      _c(
        "div",
        { staticClass: "container-fluid navbar-inner flex-nowrap" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "collapse navbar-collapse", attrs: { id: "menu" } },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-item dropdown",
                      attrs: { tag: "li", to: "/", exact: "" }
                    },
                    [
                      _c("a", { staticClass: "nav-link" }, [
                        _vm._v("DiamondEye"),
                        _c("sup", [_vm._v("®")])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-item dropdown",
                      attrs: { tag: "li", to: "/how-it-works" }
                    },
                    [
                      _c("a", { staticClass: "nav-link" }, [
                        _vm._v("How It Works")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-item dropdown",
                      attrs: { tag: "li", to: "/diamondeye-viewer" }
                    },
                    [
                      _c("a", { staticClass: "nav-link" }, [
                        _vm._v("DiamondEye"),
                        _c("sup", [_vm._v("®")]),
                        _vm._v(" Viewer")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-item dropdown",
                      attrs: { tag: "li", to: "/plan-comparison" }
                    },
                    [_c("a", { staticClass: "nav-link" }, [_vm._v("Plans")])]
                  ),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-item dropdown",
                      attrs: { tag: "li", to: "/my-account" }
                    },
                    [
                      _c("a", { staticClass: "nav-link" }, [
                        _vm._v("My Account")
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "navbar-btns" }, [
            _c("div", { staticClass: "navbar-btns-inner" }, [
              _vm._m(2),
              _vm._v(" "),
              !_vm.isAuthenticated
                ? _c(
                    "a",
                    {
                      staticClass: "navbar-btn navbar-collapse-hidden",
                      attrs: { "data-toggle": "offcanvas" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.login($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "mx-auto mb-1",
                        attrs: { "data-feather": "log-in" }
                      }),
                      _vm._v("Sign In/Up\n                ")
                    ]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "navbar-btn navbar-collapse-hidden",
                      attrs: { "data-toggle": "offcanvas" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "mx-auto mb-1",
                        attrs: { "data-feather": "log-out" }
                      }),
                      _vm._v("Signout\n                ")
                    ]
                  ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "navbar-btn",
                  attrs: {
                    id: "off-canvas-cart",
                    href: "#offcanvas-cart",
                    "data-toggle": "offcanvas"
                  }
                },
                [
                  _c("span", { staticClass: "d-block position-relative" }, [
                    _c(
                      "span",
                      { staticClass: "navbar-btn-badge bg-primary text-light" },
                      [_vm._v(_vm._s(_vm.numOfItemsInCart))]
                    ),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "mx-auto mb-1",
                      attrs: { "data-feather": "shopping-cart" }
                    }),
                    _vm._v(
                      _vm._s(_vm.totalCartAmount) + "\n                    "
                    )
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("off-canvas-cart")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "navbar-brand",
        staticStyle: { "min-width": "75px" },
        attrs: { href: "/" }
      },
      [
        _c("img", {
          attrs: { width: "75", src: "/images/logo.jpg", alt: "DiamondEye" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item dropdown" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: { target: "_blank", href: "https://retailer.diamondeye.app/" }
        },
        [_vm._v("For Retailers")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "navbar-toggler navbar-btn collapsed",
        attrs: { "data-toggle": "collapse", "data-target": "#menu" }
      },
      [
        _c("i", {
          staticClass: "mx-auto mb-1",
          attrs: { "data-feather": "menu" }
        }),
        _vm._v("Menu\n                ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }