<template>
    <div>
        <bread-crumb :pageTitle="'Your cart'"></bread-crumb>
        <div class="container pb-5 mt-n2 mt-md-n3">
            <div class="row">
                <div class="col-xl-9 col-md-8">
                    <h2 class="h6 d-flex flex-wrap justify-content-between align-items-center px-4 py-3 bg-secondary"><span>Products</span></h2>
                    <!-- Empty Cart-->
                    <span v-if="isCartEmpty">
                        <h5 class="px-4 py-3 text-center">Your cart is empty.</h5>
                        <hr class="mt-2">
                    </span>
                    <!-- Item-->
                    <span v-else>
                        <cart-item v-for="cartItem in cartItems" :key="cartItem.productId" :id="cartItem.productId" :qty="cartItem.qty" :updatable="true"></cart-item>
                    </span>
                </div>
                <!-- Sidebar-->
                <div class="col-xl-3 col-md-4 pt-3 pt-md-0">
                    <h2 class="h6 px-4 py-3 bg-secondary text-center">Subtotal</h2>
                    <div class="h3 font-weight-semibold text-center py-3">{{ totalCartAmount }}</div>
                    <hr>
                    <router-link :to="{ name: 'checkout-details'}" class="btn btn-primary btn-block" v-bind:class="{ 'disabled': isCartEmpty }"><i class="mr-2" data-feather="credit-card"></i> Proceed to Checkout</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumb from "../components/BreadCrumb";
    import CartItem from "../components/CartItem";
    import { formatAsCurrency } from '../helper';

    export default {
        name: "ShoppingCartPage",
        components: {BreadCrumb, CartItem},
        computed: {
            cartItems() {
                return this.$store.state.cart;
            },
            totalCartAmount() {
                return formatAsCurrency(this.$store.getters.getTotalCartAmount);
            },
            isCartEmpty() {
                return (this.$store.getters.getNumOfItemsInCart === 0);
            }
        }
    }
</script>

<style scoped>
</style>
