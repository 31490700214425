var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "py-3", staticStyle: { "background-color": "#1a1a1a" } },
      [
        _c(
          "div",
          {
            staticClass: "container font-size-xs text-center",
            attrs: { "aria-label": "Copyright" }
          },
          [
            _c("span", { staticClass: "text-white opacity-60 mr-1" }, [
              _vm._v(
                "Copyright © GemEx Systems, Inc. " +
                  _vm._s(_vm.getCurrentYear()) +
                  ", All rights reserved."
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "pt-5 pb-4",
        staticStyle: { "background-color": "#1f1f1f" }
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6 text-center text-sm-left" }, [
              _c("div", { staticClass: "mb-4 mb-sm-0" }, [
                _c(
                  "a",
                  { staticClass: "d-inline-block", attrs: { href: "/" } },
                  [
                    _c("img", {
                      attrs: {
                        width: "75",
                        src: "/images/logo.jpg",
                        alt: "DiamondEye"
                      }
                    })
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row pt-4" }, [
            _c("div", { staticClass: "col-sm-6 text-center text-sm-left" }, [
              _c("ul", { staticClass: "list-inline font-size-sm" }, [
                _c("li", { staticClass: "list-inline-item mr-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link-inline nav-link-light",
                      attrs: { href: "#" }
                    },
                    [_vm._v("Support")]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "list-inline-item mr-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link-inline nav-link-light",
                      attrs: { href: "/privacy" }
                    },
                    [_vm._v("Privacy Policy")]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "list-inline-item mr-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link-inline nav-link-light",
                      attrs: { href: "/terms-of-use" }
                    },
                    [_vm._v("Terms of use")]
                  )
                ])
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }