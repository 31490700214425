var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container pb-5 mb-sm-4" }, [
    _c("div", { staticClass: "pt-5" }, [
      _c("div", { staticClass: "card py-3 mt-sm-3" }, [
        _c("div", { staticClass: "card-body text-center" }, [
          _c("h3", { staticClass: "h4 pb-3" }, [
            _vm._v("Thank you for your order!")
          ]),
          _vm._v(" "),
          _vm.requiresShipping
            ? _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "Your order has been placed. Physical product(s) will be processed and shipped within 1-2 business days."
                ),
                _c("br"),
                _vm._v(
                  " If you ordered any digital products or a subscription, they should now be availabe in your DiamondEye account."
                )
              ])
            : _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "Your digital products or subscription has been processed and should now be available in your DiamoneEye account."
                )
              ]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-2" }, [
            _vm._v("Make sure you make note of your order number, which is "),
            _c("strong", [_vm._v(_vm._s(_vm.orderNumber) + ".")])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "You will be receiving an email shortly with confirmation of your order."
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }